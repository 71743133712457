import React, {Component} from "react";
import classes from './BuyDevice.css'
import {connect} from "react-redux";
import {Link as RouterLink, Redirect} from "react-router-dom";
import * as actions from "../../store/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from '../../components/UI/Button/Button'
import Modal from '../../components/UI/Modal/Modal';
import Box from "@mui/material/Box";
import styled from 'styled-components'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
// import TextField from "../../components/UI/TextField/TextField";
import {TextField} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import instance from "../../axios-spc";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Spinner from "../../components/UI/Spinner/Spinner";
import QRCode from 'qrcode.react';
import MenuItem from '@material-ui/core/MenuItem';
import crypto from 'crypto'
import Snackbar from "../../components/UI/Snackbar/Snackbar";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Error from '@material-ui/icons/Error';
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import IMAGES from "../../store/images";
import ACCOUNTS from "../../store/accounts";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card/Card";
import Divider from '@mui/material/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Link from "@material-ui/core/Link";
import {MaterialReactTable} from "material-react-table";
import FileDownloadIcon from "@mui/material/SvgIcon/SvgIcon";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu/Menu";
import ModalAccount from "../../components/UI/Modal/ModalAccount";
import {tr} from "react-date-range/src/locale";


class BuyDevice extends Component{

    state = {
        piceaLinkInserted: false,
        piceaLink: "",
        piceaData: null,
        piceaSupervalutazione: null,
        piceaImei: null,
        piceaImeiDescription: null,
        piceaPriceChange: 0,
        couponTotalPrice: 0,
        piceaImeiInvalid: false,
        modalitaPagamento: {options: [

            ], value: 0},
        tipologiaDocumento: {options: [{key: 0, value: 0, displayValue: "Seleziona documento identificazione", disabled: true}, {key: 1, value: 1, displayValue: "Carta Identità", disabled: false}, {key: 2, value: 2, displayValue: "Passaporto", disabled: false}, {key: 3, value: 3, displayValue: "Patente", disabled: false}], value: 0},
        tipologiaSupervalutazione: {options: [{key: 0, value: 0, displayValue: "È presente un telefono acquistato con supervalutazione?", disabled: true}, {key: 1, value: 1, displayValue: "Si, è presente una supervalutazione", disabled: false}, {key: 2, value: 2, displayValue: "No, nessuna supervalutazione", disabled: false}], value: 0},
        marcaSupervalutazione: {options: [{key: 0, value: 0, displayValue: "Seleziona la marca del dispositivo acquistato in supervalutazione", disabled: true},
                {key: "Samsung", value: "Samsung", displayValue: "Samsung", disabled: false},
                {key: "Xiaomi", value: "Xiaomi", displayValue: "Xiaomi", disabled: false},
                {key: "Apple", value: "Apple", displayValue: "Apple", disabled: false},
                {key: "Honor", value: "Honor", displayValue: "Honor", disabled: false},
                {key: "Motorola", value: "Motorola", displayValue: "Motorola", disabled: false},
                {key: "OPPO", value: "OPPO", displayValue: "OPPO", disabled: false},
                {key: "Realme", value: "Realme", displayValue: "Realme", disabled: false}], value: 0},
        nazione: {options: []},
        ricercaAnagrafica: {value: '', validation: {minLength: 3}, valid: false, touched: false, validationError: 'Inserire almeno 3 caratteri'},
        numeroDocumento: {value: '', validation: {required: true}, valid: false, touched: false,},
        isRicercaAnagrafica: false,
        isCreazioneAnagrafica: false,
        isModificaAnagrafica: false,
        nuovaAnagrafica: {
            cognome: {elementType: 'input', elementConfig: {type: 'input', placeholder: 'Cognome',}, value: '', validation: {required: true}, valid: false, touched: false},
            nome: {elementType: 'input', elementConfig: {type: 'input',placeholder: 'Nome',}, value: '', validation: {required: true}, valid: false, touched: false},
            codice_fiscale: {elementType: 'input', elementConfig: {type: 'input', placeholder: 'Codice Fiscale',}, value: '', validation: {required: true}, validationError: '', valid: false, touched: false},
            email: {elementType: 'input', elementConfig: {type: 'input',placeholder: 'Mail',}, value: '', validation: {required: true}, valid: false, touched: false},
            telefono_cellulare: {elementType: 'input', elementConfig: {type: 'input', placeholder: 'Cellulare',}, value: '', validation: {required: true}, valid: false, touched: false},
            citta: {elementType: 'input', elementConfig: {type: 'input',placeholder: 'Citta',}, value: '', validation: {required: true}, valid: false, touched: false},
            indirizzo: {elementType: 'input', elementConfig: {type: 'input', placeholder: 'Indirizzo',}, value: '', validation: {required: true}, valid: false, touched: false},
            numero_civico: {elementType: 'input', elementConfig: {type: 'input', placeholder: 'Civico',}, value: '', validation: {required: true}, valid: false, touched: false},
            cap: {elementType: 'input', elementConfig: {type: 'input', placeholder: 'Cap',}, value: '', validation: {required: true}, valid: false, touched: false},
            provincia: {elementType: 'input', elementConfig: {type: 'input', placeholder: 'Provincia',}, value: '', validation: {maxLength: 2}, validationError: 'Massimo 2 caratteri', valid: false, touched: false}
        },
        formAnagraficaIsValid: false,
        page: 0,
        rowsPerPage: 3,
        listaAnagrafiche: [],
        loading: false,
        clienteSelezionato: null,
        clienteDaModificare: null,
        documentoInserito: false,
        nazioneEsteraChecked: false,
        numTimes: 0,
        idRicevuta: 0,
        isStampaDocumento: false,
        isDocumentoFirmato: false,
        loadingPicea: false,
        isProcessoPending: false,
        snToDelete: null,
        isSupervalutazione: false,
        supervalutazioneInserita: false,
        valoreSupervalutazione: null,
        isAffiliatoAttivoSupervalutazione: false,
        isAffiliatoListinoRidotto: false,
        imeiSupervalutazione: {value: '', validation: {required: true}, valid: false, touched: false},
        skuSupervalutazione: {value: '', validation: {required: true}, valid: false, touched: false},
        eanSupervalutazione: {value: '', validation: {required: true}, valid: false, touched: false},
        datiSupervalutazione: {imei: 0, serial: 0, ean: 0},
        highestPrice: 0,
        highestPriceLimit: 0,
        isNecessariaRicevuta: false,
        isRicevutaAcquisita: false,
        loginCode: null,
        deviceInfo: null,
        deviceInfoError: null,
        deviceName: null,
        isValutazioneEstetica: false,
        condizioniEstetiche: {
            screen: 0,
            backGlass: 0,
            frame: 0,
            backCamera: 0
        },
        gradoValutazioneEstetica: "A",
        isValutazioneEsteticaDefinita: false,
        deviceMaxPrice: 0,
        campagne: [],
        modelNameCampagna: null,
        deviceRealPrice: 0,
        isAccountEliminato: false,
        confirmCopy: false,
        anchorEl: null,
        isInserimentoManuale: false,
        isInserimentoManualeFatto: false,
        marcaManuale: {value: '', validation: {required: true}, valid: false, touched: false},
        modelloManuale: {value: '', validation: {required: true}, valid: false, touched: false},
        memoriaManuale: {value: '', validation: {required: true}, valid: false, touched: false},
        imeiManuale: {value: '', validation: {required: true}, valid: false, touched: false},
        istruzioniEliminazioneAccount: {
            apple: '1. Apri le Impostazioni.<br />' +
                '2. Tocca il tuo nome in alto (Apple ID).<br />' +
                '3. Vai su ""Dov\'è"" → ""Trova il mio iPhone"".<br />' +
                '4. Disattiva ""Trova il mio iPhone"" (ti chiederà la password dell\'Apple ID).<br />' +
                '5. Torna indietro e scorri fino in fondo → Esci dall\'Apple ID.<br />' +
                '6. Inserisci la password e conferma.<br />',
            google: '1. Apri le Impostazioni.<br />' +
                '2. Vai su ""Account"" o ""Utenti e account"" → ""Gestione account"".<br />' +
                'Seleziona ""Google Account"".<br />' +
                '3. Tocca ""Rimuovi account"" (potrebbe essere in un menu con i tre puntini in alto a destra).<br />' +
                '4. Inserisci la password del tuo account Samsung per confermare la rimozione.<br />',
            samsung: '1. Vai su → Impostazioni → Account e backup → Gestione account.<br />' +
                '2. Seleziona Samsung Account e rimuovilo.<br />' +
                '3. Ti verrà chiesta la password per confermare la rimozione<br />',
            xiaomi: '1. Vai su Impostazioni → Account Mi.<br />' +
                '2. Tocca ""Esci"".<br />' +
                '3. Inserisci la password per confermare.<br />',
            huawei: '1. Vai su Impostazioni → Huawei ID.<br />' +
                '2. Tocca ""Esci"" e inserisci la password.<br />',
            oppo: '1. Vai su Impostazioni → Oppo ID.<br />' +
                '2. Tocca ""Esci"", inserisci la password e conferma.<br />',
            realme: '1. Vai su Impostazioni → Realme ID.<br />' +
                '2. Tocca ""Esci"", inserisci la password e conferma.<br />',
            oneplus: '1. Vai su Impostazioni → Account → OnePlus Account.<br />' +
                '2. Tocca ""Esci"", inserisci la password e conferma.<br />',
            vivo: '1. Apri le Impostazioni.<br />' +
                '2. Vai su ""Account e sincronizzazione"" → ""Vivo Account"".<br />' +
                '3. Tocca ""Esci"" e inserisci la password.<br />',
            meizu: '1.Apri le Impostazioni.<br />' +
                '2. Vai su ""Account Meizu"".<br />' +
                '3. Tocca ""Esci"" e inserisci la password.<br />'
        }

    };

    componentDidMount() {
        this.props.onAuthCheckState();
        // this.getBrands();
        this.getMemory();
        let newOptions = [
            {key: 0, value: 0, displayValue: "Seleziona", disabled: true},
            {key: 1, value: 1, displayValue: "Buono", disabled: false},
            {key: 2, value: 2, displayValue: "Contanti", disabled: false},
            {key: 3, value: 3, displayValue: "Stripe", disabled: false}
        ];
        if (localStorage.getItem("idAffiliato") == 589) {
            newOptions = [
                {key: 0, value: 0, displayValue: "Seleziona", disabled: true},
                {key: 1, value: 1, displayValue: "Buono", disabled: false},
            ]
        }
        let updateOption = {...this.state.modalitaPagamento};
        updateOption.options = newOptions;
        this.setState({modalitaPagamento: updateOption});

        let idAffiliato = localStorage.getItem("idAffiliato");
        let affiliatiAttiviList = window.config.idAffiliatiAttiviSupervalutazione;
        let affiliatiAttiviArray = [];
        if (affiliatiAttiviList.length > 0) {
            affiliatiAttiviArray = affiliatiAttiviList.split(",");
        }
        let isAffiliatoAttivo = false;
        for (let i = 0; i < affiliatiAttiviArray.length && !isAffiliatoAttivo; ++i) {
            isAffiliatoAttivo = affiliatiAttiviArray[i] === idAffiliato
        }

        let affiliatiListinoRidottoList = window.config.idAffiliatiListinoRidotto;
        let affiliatiListinoRidottoArray = [];
        if (affiliatiListinoRidottoList.length > 0) {
            affiliatiListinoRidottoArray = affiliatiListinoRidottoList.split(",");
        }
        let isAffiliatoListinoRidotto = false;
        for (let i = 0; i < affiliatiListinoRidottoArray.length && !isAffiliatoListinoRidotto; ++i) {
            isAffiliatoListinoRidotto = affiliatiListinoRidottoArray[i] === idAffiliato
        }

        let highestPriceLimit = window.config.limiteValorePerRicevuta;

        this.setState({isAffiliatoAttivoSupervalutazione: isAffiliatoAttivo, isAffiliatoListinoRidotto: isAffiliatoListinoRidotto, highestPriceLimit: highestPriceLimit});
    }

    // getBrands = () => {
    //     var postData = {
    //         id_negozio: localStorage.getItem("idNegozio"),
    //     };
    //     instance.post('/getPriceCheckBrands', postData).then(response => {
    //         const updatedForm = {
    //             ...this.state.marcaManuale
    //         };
    //         let newOption = response.data;
    //         updatedForm.options = newOption;
    //         this.setState({marcaManuale: updatedForm})
    //     });
    // };

    getMemory = () => {
        var postData = {
            id_model_name: null
        };
        instance.post('/getPriceModelsMemory', postData).then(response => {
            const updatedForm = {
                ...this.state.memoriaManuale
            };
            let newOption = response.data;
            updatedForm.options = newOption;
            this.setState({memoriaManuale: updatedForm})
        });
    };

    submitHandler = (event) => {
        if (!this.state.piceaData) {

            // let apiKey = "BmzmWX4gd7yMsK3htPupFQZ5";
            // let clientId = "1B08721A-88DB-4A13-920F-3B57DA5A8C75";

            // clientId = "D39A3924-4DF9-4A58-B46F-20D08933C85B";
            // apiKey = "BWyb8yTVUR5egEm7nPspQkUJ";

            let clientId = localStorage.getItem("clientId");
            let apiKey = localStorage.getItem('clientKey');

            let body = '{"session_id" : "XXX"}';
            let bodyReport = '{"uid": "XXX"}';
            let updatedBody = body.replace("XXX", this.state.piceaLink);
            let updatedBodyReport = bodyReport.replace("XXX", this.state.piceaLink);
            let dataToHash = apiKey + clientId + updatedBody;
            let dataToHashReport = apiKey + clientId + updatedBodyReport;
            let signature    = crypto.createHash('sha256').update(dataToHash).digest('hex');
            let signatureReport    = crypto.createHash('sha256').update(dataToHashReport).digest('hex');
            // chiamata a picea
            var postData = {
                body: updatedBody,
                apiKey: apiKey,
                clientId: clientId,
                signature: signature
            };
            this.setState({loadingPicea: true});
            instance.post('/getPiceaData', postData)
                .then(response => {
                    const result = (JSON.parse(response.data));
                    console.log(result);
                    let report = result.report;

                    if (result.status !== 0) {
                        var postDataReport = {
                            body: updatedBodyReport,
                            apiKey: apiKey,
                            clientId: clientId,
                            signature: signatureReport
                        };

                        instance.post('/getPiceaDataReport', postDataReport)
                            .then(response => {
                                const result = (JSON.parse(response.data));
                                console.log(result);
                                let report = result.report;
                                if (result.status !== 0) {
                                    this.setState({piceaData: "Nessun Report Trovato", loadingPicea: false})
                                } else {
                                    let tr;

                                    const tradingOperation = report.operation.workflow_details.trading;
                                    if (tradingOperation !== undefined) {
                                        const tradingId = tradingOperation.uid;
                                        let price = 0;
                                        let highestPrice = 0;
                                        if (tradingOperation.trading_details.deal_accepted === true) {
                                            price = tradingOperation.trading_details.price_details.price_string;
                                            highestPrice = tradingOperation.trading_details.highest_price_details.price;
                                            this.setState({highestPrice: highestPrice})
                                            if (this.state.isAffiliatoListinoRidotto) price = (+price * 0.8).toFixed(2);
                                            let campaignPrice = 0;
                                            let imeiSupervalutazione = null;
                                            let price_change = 0;
                                            let imei = null;
                                            let total_price = 0;
                                            const deviceData = {
                                                serial_number: report.operation.device.serial,
                                                brand_name: report.operation.device.manufacturer,
                                                model_name: report.operation.device.model_name,
                                                model_code: report.operation.device.model_code,
                                                region_code: null,
                                                imei: report.operation.device.imei,
                                                imei2: report.operation.device.imei2,
                                                meid: report.operation.device.meid,
                                                color: (report.operation.device.color == null || false) ? 'Various' : report.operation.device.color,
                                                memory_gb: report.operation.device.memory_rom,
                                                report_id: this.state.piceaLink,
                                                evaluation_id: tradingId,
                                                valore_iniziale: 0,
                                                valore_acquisto: price,
                                                id_operatore: localStorage.getItem("userId"),
                                                id_negozio: localStorage.getItem("idNegozio"),
                                                // grado: customQuestion != null ? customQuestion : "N.D."
                                            };
                                            instance.post('/setNewDevice', deviceData).then(resp => {
                                                if (resp.data[0].ret_code === 0) {
                                                    this.setState({
                                                        piceaData: deviceData,
                                                        numTimes: resp.data[0].num_times,
                                                        loadingPicea: false,
                                                        isProcessoPending: false,
                                                        piceaSupervalutazione: campaignPrice,
                                                        piceaImeiDescription: imeiSupervalutazione,
                                                        piceaImei: imei,
                                                        piceaPriceChange: price_change,
                                                        couponTotalPrice: total_price,
                                                        isNecessariaRicevuta: +highestPrice > +this.state.highestPriceLimit
                                                    });

                                                } else if (resp.data[0].ret_code === 3) {
                                                    this.setState({
                                                        piceaData: resp.data[0].ret_message.replaceAll("_", " "),
                                                        loadingPicea: false,
                                                        isProcessoPending: true,
                                                        isNecessariaRicevuta: false,
                                                        snToDelete: deviceData.serial_number
                                                    })
                                                } else {
                                                    this.setState({
                                                        piceaData: resp.data[0].ret_message.replaceAll("_", " "),
                                                        loadingPicea: false,
                                                        isProcessoPending: false
                                                    })
                                                }
                                            });
                                        } else {
                                            this.setState({
                                                piceaData: "Telefono non idoneo per il trading",
                                                loadingPicea: false
                                            })
                                        }
                                    } else {
                                        this.setState({piceaData: "Non risulta essere un report di TRADING", loadingPicea: false})
                                    }
                                }
                            })
                    } else {
                        let tr;
                        let customQuestion;
                        for (let x =0; x < report.operations.length; ++x) {
                            if (report.operations[x].type_string === 'Trading') {
                                tr = report.operations[x]
                            }
                            if (report.operations[x].type_string === 'CustomQuestion') {
                                customQuestion = report.operations[x]
                            }

                        }
                        const tradingOperation = tr;
                        if (tradingOperation !== undefined) {
                            const tradingId = tradingOperation.uid;
                            let price = 0;
                            let campaignPrice = 0;
                            let imeiSupervalutazione = null;
                            let price_change = 0;
                            let imei = null;
                            let total_price = 0;
                            let highestPrice = 0;
                            if (tradingOperation.trading_details.deal_accepted === true) {
                                price = tradingOperation.trading_details.price_details.price_string;
                                highestPrice = tradingOperation.trading_details.highest_price_details.price;
                                if (this.state.isAffiliatoListinoRidotto) price = (+price * 0.8).toFixed(2);
                                this.setState({highestPrice: highestPrice})

                                const deviceData = {
                                    serial_number: report.device.serial,
                                    brand_name: report.device.manufacturer,
                                    model_name: report.device.model_name,
                                    model_code: report.device.model_code,
                                    region_code: null,
                                    imei: report.device.imei,
                                    imei2: report.device.imei2,
                                    meid: report.device.meid,
                                    color: (report.device.color == null || false) ? 'Various' : report.device.color,
                                    memory_gb: report.device.memory_rom,
                                    report_id: this.state.piceaLink,
                                    evaluation_id: tradingId,
                                    valore_iniziale: 0,
                                    valore_acquisto: price,
                                    id_operatore: localStorage.getItem("userId"),
                                    id_negozio: localStorage.getItem("idNegozio"),
                                    // grado: grado
                                };
                                instance.post('/setNewDevice', deviceData).then(resp => {
                                    // axios.post('http://localhost:8989/api/setNewDevice', deviceData).then(resp => {
                                    if (resp.data[0].ret_code === 0) {
                                        this.setState({
                                            piceaData: deviceData,
                                            numTimes: resp.data[0].num_times,
                                            loadingPicea: false,
                                            isProcessoPending: false,
                                            piceaSupervalutazione: campaignPrice,
                                            piceaImeiDescription: imeiSupervalutazione,
                                            piceaImei: imei,
                                            piceaPriceChange: price_change,
                                            couponTotalPrice: total_price,
                                            isNecessariaRicevuta: +highestPrice > +this.state.highestPriceLimit
                                        });



                                    } else if (resp.data[0].ret_code === 3) {
                                        this.setState({
                                            piceaData: resp.data[0].ret_message.replaceAll("_", " "),
                                            loadingPicea: false,
                                            isProcessoPending: true,
                                            isNecessariaRicevuta: false,
                                            snToDelete: deviceData.serial_number
                                        })
                                    } else {
                                        this.setState({
                                            piceaData: resp.data[0].ret_message.replaceAll("_", " "),
                                            loadingPicea: false,
                                            isProcessoPending: false
                                        })
                                    }
                                });
                            } else {
                                this.setState({piceaData: "Telefono non idoneo per il trading", loadingPicea: false})
                            }
                        } else {
                            this.setState({piceaData: "Non risulta essere un report di TRADING", loadingPicea: false})
                        }

                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({loadingPicea: false});
                });

        } else {
            this.props.onResetLinkPicea();
            const updatedForm = {
                ...this.state.modalitaPagamento
            };
            updatedForm.value = 0;
            const updatedFormDoc = {
                ...this.state.numeroDocumento
            };
            updatedFormDoc.value = "";
            const updatedFormCliente = {
                ...this.state.ricercaAnagrafica
            };
            updatedFormCliente.value = "";
            const updatedFormTipoDoc = {
                ...this.state.tipologiaDocumento
            };
            updatedFormTipoDoc.value = "";
            this.setState({highestPrice: 0,piceaData: null, modalitaPagamento: updatedForm, clienteSelezionato: null, documentoInserito: false, numeroDocumento: updatedFormDoc, ricercaAnagrafica: updatedFormCliente, tipologiaDocumento: updatedFormTipoDoc, piceaSupervalutazione: null, piceaImei: null, piceaPriceChange: 0, piceaImeiDescription: null, couponTotalPrice: 0, piceaImeiInvalid: false, isNecessariaRicevuta: false, isRicevutaAcquisita: false});
        }
    };

    openModalRicercaCliente = (value) => {
        console.log(value);
        this.setState({loading: true});
        let clienti = [];
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
            cognome: value,
            codice_fiscale: null,
            ragione_sociale: null,
            partita_iva: null
        };

        instance.post('/getClientiByCognome', postData)
        // axios.post('http://localhost:8989/api/getClientiByCognome', postData)
            .then(response => {
                for (let key in response.data) {
                    clienti.push({...response.data[key], id: key})
                }
                this.setState({listaAnagrafiche: clienti, loading: false});
                console.log(clienti.length)
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false});
            });
        this.setState({isRicercaAnagrafica: true})
    };

    openModalCreazioneAnagrafica = () => {
        this.getListaNazioni();
        this.setState({isCreazioneAnagrafica: true})
    };

    openModalCondizioniEstetiche = () => {
        this.setState({isValutazioneEstetica: true, isValutazioneEsteticaDefinita: false})
    };

    valutazioneEsteticaCloseHandler = () => {

        this.setState({isValutazioneEstetica: false})
    };

    ricercaCancelHandler = () => {
        this.setState({isRicercaAnagrafica: false, isCreazioneAnagrafica: false, isModificaAnagrafica: false, clienteDaModificare: null})
    };

    selezionaAnagrafica = (cliente) => {
        this.setState({isRicercaAnagrafica: false, clienteSelezionato: cliente})
    };

    modificaAnagrafica = (cliente) => {
        this.setState({isRicercaAnagrafica: false, isModificaAnagrafica: true, clienteDaModificare: cliente})
    };

    annullaModalitaPagamento = () => {
        const updatedForm = {
            ...this.state.modalitaPagamento
        };
        updatedForm.value = 0;
        const updatedFormDoc = {
            ...this.state.numeroDocumento
        };
        updatedFormDoc.value = "";
        const updatedFormCliente = {
            ...this.state.ricercaAnagrafica
        };
        updatedFormCliente.value = "";
        const updatedFormTipoDoc = {
            ...this.state.tipologiaDocumento
        };
        updatedFormTipoDoc.value = "";
        this.setState({modalitaPagamento: updatedForm, clienteSelezionato: null, documentoInserito: false, numeroDocumento: updatedFormDoc, ricercaAnagrafica: updatedFormCliente, tipologiaDocumento: updatedFormTipoDoc});
    }

    annullaCliente = () => {
        const updatedFormDoc = {
            ...this.state.numeroDocumento
        };
        updatedFormDoc.value = "";
        const updatedFormCliente = {
            ...this.state.ricercaAnagrafica
        };
        updatedFormCliente.value = "";
        const updatedFormTipoDoc = {
            ...this.state.tipologiaDocumento
        };
        updatedFormTipoDoc.value = "";
        this.setState({clienteSelezionato: null, documentoInserito: false, numeroDocumento: updatedFormDoc, ricercaAnagrafica: updatedFormCliente, tipologiaDocumento: updatedFormTipoDoc});
    };

    annullaDoc = () => {
        const updatedFormDoc = {
            ...this.state.numeroDocumento
        };
        updatedFormDoc.value = "";
        const updatedFormTipoDoc = {
            ...this.state.tipologiaDocumento
        };
        updatedFormTipoDoc.value = "";
        this.setState({documentoInserito: false, numeroDocumento: updatedFormDoc, tipologiaDocumento: updatedFormTipoDoc});
    };

    annullaTelefono = () => {
        const updatedFormDoc = {
            ...this.state.imeiSupervalutazione
        };
        updatedFormDoc.value = "";
        const updatedFormTipoDoc = {
            ...this.state.tipologiaSupervalutazione
        };
        updatedFormTipoDoc.value = 0;
        const updatedFormMarcaSupervalutazione = {
            ...this.state.marcaSupervalutazione
        };
        updatedFormMarcaSupervalutazione.value = 0;
        const updatedSkuDoc = {
            ...this.state.skuSupervalutazione
        };
        updatedSkuDoc.value = "";
        const updatedEanDoc = {
            ...this.state.eanSupervalutazione
        };
        updatedEanDoc.value = "";
        let updatedCoupon = {
            serial_number: this.state.piceaData.serial_number,
            coupon_value: +this.state.piceaData.valore_acquisto
        };
        instance.post('/updateCoupon', updatedCoupon).then(function (resp) {
            console.log(resp)
        });

        this.setState({valoreSupervalutazione: null, supervalutazioneInserita: false, isSupervalutazione: false, imeiSupervalutazione: updatedFormDoc, tipologiaSupervalutazione: updatedFormTipoDoc, piceaImeiInvalid: false, marcaSupervalutazione: updatedFormMarcaSupervalutazione, skuSupervalutazione: updatedSkuDoc, eanSupervalutazione: updatedEanDoc, modelNameCampagna: null});
    };

    annullaNumeroDoc = () => {
        const updatedFormDoc = {
            ...this.state.numeroDocumento
        };
        updatedFormDoc.value = "";
        this.setState({documentoInserito: false, numeroDocumento: updatedFormDoc});
    };

    annullaImei = () => {
        const updatedFormDoc = {
            ...this.state.imeiSupervalutazione
        };
        updatedFormDoc.value = "";
        const updatedSkuDoc = {
            ...this.state.skuSupervalutazione
        };
        updatedSkuDoc.value = "";
        const updatedFormTipoDoc = {
            ...this.state.tipologiaSupervalutazione
        };
        updatedFormTipoDoc.value = 0;
        const updatedEanDoc = {
            ...this.state.eanSupervalutazione
        };
        updatedEanDoc.value = "";
        let updatePrice = this.state.deviceRealPrice - this.state.valoreSupervalutazione;
        if (this.state.piceaData) {
            let updatedCoupon = {
                serial_number: this.state.piceaData.serial_number,
                coupon_value: updatePrice,
                num_times: this.state.numTimes
            };
            instance.post('/updateCoupon', updatedCoupon).then(function (resp) {
                if (resp.data[0].ret_code === 0) {
                    console.log(resp.data[0])
                }
            });
        }
        this.setState({deviceRealPrice: updatePrice, supervalutazioneInserita: false, imeiSupervalutazione: updatedFormDoc, valoreSupervalutazione: null, skuSupervalutazione: updatedSkuDoc, tipologiaSupervalutazione: updatedFormTipoDoc, eanSupervalutazione: updatedEanDoc, modelNameCampagna: null});
    };

    handleChange = (event) => {
        console.log("onChange");
        const value = event.target.value;
        const oldState = {...this.state};
        const oldForm = {...oldState.nuovaAnagrafica};
        oldForm[event.target.name].value = value;
        this.setState({nuovaAnagrafica: oldForm})
    };

    handleChangeModifica = (event) => {
        console.log("onChange");
        const value = event.target.value;
        const oldAnagrafica = {...this.state.clienteDaModificare};
        oldAnagrafica[event.target.name] = value;
        this.setState({clienteDaModificare: oldAnagrafica})
    };

    handleChangeAnagrafica = (event, inputIdentifier) => {
        const updatedForm = {
            ...this.state.nuovaAnagrafica
        };
        const updatedFormElement = {
            ...updatedForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        if (inputIdentifier === 'codice_fiscale') {
            updatedFormElement.valid = updatedFormElement.value.match(/^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/);
            !updatedFormElement.valid ? updatedFormElement.validationError = 'Codice Fiscale non valido' : updatedFormElement.validationError = '';
            if (this.state.nazioneEsteraChecked) {
                updatedFormElement.valid = true;
                updatedFormElement.validationError = '';
            }
        } else if (inputIdentifier === 'email') {
            updatedFormElement.valid = updatedFormElement.value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            !updatedFormElement.valid ? updatedFormElement.validationError = 'Indirizzo mail non valido' : updatedFormElement.validationError = '';
        } else {
            updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
            !updatedFormElement.valid ? updatedFormElement.validationError = 'Campo obbligatorio' : updatedFormElement.validationError = '';
            if (inputIdentifier === 'provincia') updatedFormElement.validationError = 'Massimo 2 caratteri'
        }
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;
        let formIsValid = true;
        for (let inputId in updatedForm) {
            formIsValid = updatedForm[inputId].valid && formIsValid
        }
        if (this.state.nazioneEsteraChecked) {
            formIsValid = true;
        }
        this.setState({nuovaAnagrafica: updatedForm, formAnagraficaIsValid: formIsValid});
    };

    setValutazioneEstetica = () => {
        let deprezzamento = 1;
        switch (this.state.gradoValutazioneEstetica) {
            case "A":
                deprezzamento = 1;
                break;
            case "A-":
                deprezzamento = 0.95;
                break;
            case "B+":
                deprezzamento = 0.9;
                break;
            case "B-":
                deprezzamento = 0.8;
                break;
            case "C+":
                deprezzamento = 0.5;
                break;
            case "C-":
                deprezzamento = 0.2;
                break;
        }
        if (this.state.deviceRealPrice > 2) {
            let oldPrice = this.state.deviceRealPrice;
            this.setState({isValutazioneEstetica: false, isValutazioneEsteticaDefinita: true, deviceRealPrice: (oldPrice * deprezzamento).toFixed(2)})
        }
        this.setState({isValutazioneEstetica: false, isValutazioneEsteticaDefinita: true});
        console.log("GRADO " + this.state.gradoValutazioneEstetica);
    };

    confermaEliminazioneAccount = () => {
        this.setState({ isAccountEliminato: true});
    }

    creaAnagrafica = () => {
        var postData = {
            id_cliente: null,
            nome: this.state.nuovaAnagrafica.nome.value,
            cognome: this.state.nuovaAnagrafica.cognome.value,
            codice_fiscale: this.state.nuovaAnagrafica.codice_fiscale.value,
            ragione_sociale: null,
            partita_iva: null,
            codice_destinatario_b2b: null,
            pec_destinatario_b2b: null,
            is_associazione: null,
            indirizzo: this.state.nuovaAnagrafica.indirizzo.value,
            numero_civico: this.state.nuovaAnagrafica.numero_civico.value,
            citta: this.state.nuovaAnagrafica.citta.value,
            cap: this.state.nuovaAnagrafica.cap.value,
            email: this.state.nuovaAnagrafica.email.value,
            sigla_provincia: this.state.nuovaAnagrafica.provincia.value,
            telefono_cellulare: this.state.nuovaAnagrafica.telefono_cellulare.value,
            nazione: this.state.nuovaAnagrafica.nazione,
            id_negozio: localStorage.getItem("idNegozio"),
        };
        if (this.state.clienteDaModificare) {
            let sigla = this.state.clienteDaModificare.provincia;
            this.state.clienteDaModificare.sigla_provincia = sigla;
            this.state.clienteDaModificare.id_negozio = localStorage.getItem("idNegozio");
            postData = this.state.clienteDaModificare;
        }
        instance.post('/setCliente', postData)
        // axios.post('http://localhost:8989/api/setCliente', postData)
            .then(response => {
                if (response.data[0].ret_code === 0) {
                    let nuovoCliente = {...postData};
                    nuovoCliente.id_cliente = response.data[0].id_cliente;
                    this.setState({clienteSelezionato: nuovoCliente, isCreazioneAnagrafica: false, isModificaAnagrafica: false})
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false});
            });
        this.setState({isCreazioneAnagrafica: false})
    };

    inputonChangeHandler = (event, id) => {
        switch (id) {
            case "modalitaPagamento": {
                const updatedForm = {
                    ...this.state.modalitaPagamento
                };
                updatedForm.value = event.target.value;
                this.setState({modalitaPagamento: updatedForm});
                break;
            }
            case "ricercaAnagrafica": {
                const updatedForm = {
                    ...this.state.ricercaAnagrafica
                };
                updatedForm.value = event.target.value;
                updatedForm.valid = this.checkValidity(updatedForm.value, updatedForm.validation);
                updatedForm.touched = true;
                this.setState({ricercaAnagrafica: updatedForm});
                break;
            }
            case "numeroDocumento": {
                const updatedForm = {
                    ...this.state.numeroDocumento
                };
                updatedForm.value = event.target.value;
                updatedForm.valid = this.checkValidity(updatedForm.value, updatedForm.validation);
                updatedForm.touched = true;
                this.setState({numeroDocumento: updatedForm});
                break;
            }
            case "tipologiaDocumento": {
                const updatedForm = {
                    ...this.state.tipologiaDocumento
                };
                updatedForm.value = event.target.value;
                this.setState({tipologiaDocumento: updatedForm});
                break;
            }
            case "tipologiaSupervalutazione": {
                const updatedForm = {
                    ...this.state.tipologiaSupervalutazione
                };
                updatedForm.value = event.target.value;
                this.setState({tipologiaSupervalutazione: updatedForm});
                break;
            }
            case "imeiSupervalutazione": {
                const updatedForm = {
                    ...this.state.imeiSupervalutazione
                };
                updatedForm.value = event.target.value;
                this.setState({imeiSupervalutazione: updatedForm});
                break;
            }
            case "skuSupervalutazione": {
                const updatedForm = {
                    ...this.state.skuSupervalutazione
                };
                updatedForm.value = event.target.value;
                this.setState({skuSupervalutazione: updatedForm});
                break;
            }
            case "eanSupervalutazione": {
                const updatedForm = {
                    ...this.state.eanSupervalutazione
                };
                updatedForm.value = event.target.value;
                this.setState({eanSupervalutazione: updatedForm});
                break;
            }
            case "marcaSupervalutazione": {
                const updatedForm = {
                    ...this.state.marcaSupervalutazione
                };
                updatedForm.value = event.target.value;
                this.setState({marcaSupervalutazione: updatedForm});
                break;
            }
            case "piceaLink": {
                this.setState({piceaLink: event.target.value});
                break;
            }
            case "valoreSupervalutazione": {
                this.setState({valoreSupervalutazione: event.target.value});
                break;
            }
            case "modelloManuale": {
                const updatedForm = {
                    ...this.state.modelloManuale
                };
                updatedForm.value = event.target.value;
                this.setState({modelloManuale: updatedForm});
                break;
            }
            case "imeiManuale": {
                const updatedForm = {
                    ...this.state.imeiManuale
                };
                updatedForm.value = event.target.value;
                this.setState({imeiManuale: updatedForm});
                break;
            }
            case "memoriaManuale": {
                const updatedForm = {
                    ...this.state.memoriaManuale
                };
                updatedForm.value = event.target.value;
                this.setState({memoriaManuale: updatedForm});
                break;
            }
            case "marcaManuale": {
                const updatedForm = {
                    ...this.state.marcaManuale
                };
                updatedForm.value = event.target.value;
                this.setState({marcaManuale: updatedForm});
                break;
            }
            default: {
                const oldState = {
                    ...this.state
                };
                this.setState({state: oldState});
                break;
            }
        }

    };

    checkValidity(value, rules) {
        let isValid = true;
        if (rules) {
            if (rules.required) {
                isValid = value.trim() !== '' && isValid;
            }
            if (rules.minLength) {
                isValid = value.length >= rules.minLength && isValid
            }
            if (rules.maxLength) {
                isValid = value.length <= rules.maxLength && isValid
            }
        }
        return isValid
    }

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: +event.target.value, page: 0})
    };
    handleChangeCheckBox = (event) => {
        this.setState({nazioneEsteraChecked: event.target.checked})
    };
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        const updatedFormMarcaSupervalutazione = {
            ...this.state.marcaSupervalutazione
        };
        updatedFormMarcaSupervalutazione.value = 0;
        this.setState({isStampaDocumento: false, piceaImeiInvalid: false, marcaSupervalutazione: updatedFormMarcaSupervalutazione, valoreSupervalutazione: null, confirmCopy: false})
    };

    selezionaSupervalutazione = (data) => {
        console.log(data);
        const updatedForm = {
            ...this.state.tipologiaSupervalutazione
        };
        updatedForm.value = 1;
        const updatedFormMarca = {
            ...this.state.marcaSupervalutazione
        };
        updatedFormMarca.value = data.brand_campagna;
        const updateFormDati = {
            ...this.state.datiSupervalutazione
        };
        updateFormDati.imei = data.imei;
        updateFormDati.serial = data.serial;
        updateFormDati.ean = data.ean;
        this.setState({marcaSupervalutazione: updatedFormMarca, tipologiaSupervalutazione: updatedForm,valoreSupervalutazione: data.supervalutazione, modelNameCampagna: data.model_name_campagna, datiSupervalutazione: updateFormDati });
    };


    confermaSupervalutazione = () => {
        console.log("é stata inserito un imei per la supervalutazione " + this.state.imeiSupervalutazione);
        this.setState({supervalutazioneInserita: true});

        let supervalutazioneValore = +this.state.valoreSupervalutazione;
        let updatedCoupon = {
            serial_number: this.state.piceaData.serial_number,
            coupon_value: +this.state.deviceRealPrice + +supervalutazioneValore,
            num_times: this.state.numTimes
        };

        let numTimes = this.state.numTimes;
        let valoreAcquisto = this.state.deviceRealPrice;

        let samsungInfo = {
            imei: this.state.imeiSupervalutazione.value,
        };

        if (this.state.marcaSupervalutazione.value === 'Samsung' && this.state.datiSupervalutazione.imei === 1) {



            instance.post('/checkSamsungImei', samsungInfo).then(resp => {
                let data = resp.data;

                if (data.imei_used === false && data.status === 'IMEI_FOUND') {
                    this.setState({piceaImeiInvalid: false});
                    instance.post('/updateCoupon', updatedCoupon).then(function (resp) {
                        if (resp.data[0].ret_code === 0) {
                            let imeiSupervalutazione = {
                                serial_number: updatedCoupon.serial_number,
                                num_times: numTimes,
                                imei_acquistato: data.imei,
                                sku: data.sku,
                                ean: data.ean
                            };
                            instance.post('/setImeiSupervalutazione', imeiSupervalutazione).then(function (r) {
                                console.log(r);
                                let samsungInfo = {
                                    imei: data.imei,
                                    marketing_name: 'SmartphoneCash',
                                    brand_name: "Eco Store",
                                    device_value: +valoreAcquisto,
                                    voucher_value: (+updatedCoupon.coupon_value),
                                    store_name: localStorage.getItem("ragione_sociale") != null ? localStorage.getItem("ragione_sociale") : 'Eco Store',
                                    store_city: localStorage.getItem("nomeNegozio").substring(0, localStorage.getItem("nomeNegozio").indexOf("-")),
                                    store_province: "PV"
                                };
                                console.log(samsungInfo);
                                instance.post('/setSamsungImei', samsungInfo).then(resp => {
                                    console.log(resp.data);
                                    this.setState({deviceRealPrice: updatedCoupon.coupon_value})
                                }).catch(err => {
                                    console.log(err)
                                })
                            })
                        }
                    });
                } else {
                    this.setState({piceaImeiInvalid: true});
                }
            })
        } else {
            console.log("Scelto Altro");
            let that = this;
            this.setState({piceaImeiInvalid: false});
            let otherImei = this.state.imeiSupervalutazione.value;
            let sku = this.state.skuSupervalutazione.value === '' ? (this.state.marcaSupervalutazione.value + '-SKU') : this.state.skuSupervalutazione.value;
            let ean = this.state.eanSupervalutazione.value === '' ? (this.state.marcaSupervalutazione.value + '-EAN') : this.state.skuSupervalutazione.value;
            instance.post('/updateCoupon', updatedCoupon).then(function (resp) {
                if (resp.data[0].ret_code === 0) {
                    let imeiSupervalutazione = {
                        serial_number: updatedCoupon.serial_number,
                        num_times: numTimes,
                        imei_acquistato: otherImei,
                        sku: sku,
                        ean: ean
                    };
                    instance.post('/setImeiSupervalutazione', imeiSupervalutazione).then(function (r) {
                        console.log(r);
                        that.setState({deviceRealPrice: updatedCoupon.coupon_value})
                    }).catch(err => {
                        console.log(err)
                    })
                }
            });
        };
    };

    confermaDocumento = () => {

        if (this.state.modalitaPagamento.value === 1) {
            let couponData = {
                serial_number: this.state.piceaData.serial_number,
                num_times: this.state.numTimes,
            };
            instance.post('/setCoupon', couponData).then(resp => {
                // axios.post('http://localhost:8989/api/setCoupon', couponData).then(resp => {
                let postData = {
                    serial_number: this.state.piceaData.serial_number,
                    num_times: this.state.numTimes,
                    id_negozio: localStorage.getItem("idNegozio"),
                    id_operatore: localStorage.getItem("userId"),
                    id_cliente: this.state.clienteSelezionato.id_cliente,
                    id_tipo_documento: this.state.tipologiaDocumento.value,
                    numero_documento: this.state.numeroDocumento.value,
                    is_coupon: 1,
                    coupon_serial_number: resp.data[0].coupon_serial_number,
                };
                instance.post('/setRicevuta', postData)
                // axios.post('http://localhost:8989/api/setRicevuta', postData)
                    .then(response => {
                        this.setState({documentoInserito: true})
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        } else {
            let postData = {
                serial_number: this.state.piceaData.serial_number,
                num_times: this.state.numTimes,
                id_negozio: localStorage.getItem("idNegozio"),
                id_operatore: localStorage.getItem("userId"),
                id_cliente: this.state.clienteSelezionato.id_cliente,
                id_tipo_documento: this.state.tipologiaDocumento.value,
                numero_documento: this.state.numeroDocumento.value,
                is_coupon: 0,
                coupon_serial_number: null,
            };
            instance.post('/setRicevuta', postData)
            // axios.post('http://localhost:8989/api/setRicevuta', postData)
                .then(response => {
                    this.setState({documentoInserito: true})
                })
                .catch(error => {
                    console.log(error);
                });
        }

    };

    confermaDocumentoPhoneCheck = () => {

        console.log(this.state.deviceInfo.make);
        const deviceData = {
            serial_number: this.state.deviceInfo.loginCode,
            brand_name: this.state.deviceInfo.make,
            model_name: this.state.deviceInfo.modelName != '' ? this.state.deviceInfo.modelName : this.state.deviceInfo.modelNo,
            model_code: this.state.deviceInfo.modelNo,
            region_code: null,
            num_times: this.state.numTimes == 0 ? 1 : this.state.numTimes,
            imei: this.state.deviceInfo.imei,
            imei2: this.state.deviceInfo.imei2,
            meid: null,
            color: (this.state.deviceInfo.color == null || this.state.deviceInfo.color == '' ) ? 'Various' : this.state.deviceInfo.color,
            memory_gb: this.state.deviceInfo.memory.replaceAll(" ", '').replaceAll(".00", '').replaceAll('GB', '').replaceAll('TB', ''),
            report_id: this.state.deviceInfo.id,
            evaluation_id: this.state.deviceInfo.id,
            valore_iniziale: 0,
            valore_acquisto: this.state.deviceRealPrice,
            id_operatore: localStorage.getItem("userId"),
            id_negozio: localStorage.getItem("idNegozio"),
            grado_estetico: this.state.gradoValutazioneEstetica
        };
        instance.post('/setNewDevice', deviceData).then(resp => {
            let numTimes = 1;
            if (resp.data[0].ret_code === 0) {
                numTimes = resp.data[0].num_times;
                this.setState({
                    piceaData: deviceData,
                    numTimes: resp.data[0].num_times,
                    loadingPicea: false,
                    isProcessoPending: false,
                    // piceaSupervalutazione: campaignPrice,
                    // piceaImeiDescription: imeiSupervalutazione,
                    // piceaImei: imei,
                    // piceaPriceChange: price_change,
                    // couponTotalPrice: total_price,
                    // isNecessariaRicevuta: +highestPrice > +this.state.highestPriceLimit
                });
                if (this.state.modalitaPagamento.value === 1) {
                    let couponData = {
                        serial_number: this.state.deviceInfo.loginCode,
                        num_times: numTimes,
                    };
                    instance.post('/setCoupon', couponData).then(resp => {
                        // axios.post('http://localhost:8989/api/setCoupon', couponData).then(resp => {
                        let postData = {
                            serial_number: this.state.deviceInfo.loginCode,
                            num_times: numTimes,
                            id_negozio: localStorage.getItem("idNegozio"),
                            id_operatore: localStorage.getItem("userId"),
                            id_cliente: this.state.clienteSelezionato.id_cliente,
                            id_tipo_documento: this.state.tipologiaDocumento.value,
                            numero_documento: this.state.numeroDocumento.value,
                            is_coupon: 1,
                            coupon_serial_number: resp.data[0].coupon_serial_number,
                        };
                        instance.post('/setRicevuta', postData)
                        // axios.post('http://localhost:8989/api/setRicevuta', postData)
                            .then(response => {
                                this.setState({documentoInserito: true})
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    });
                } else {
                    let postData = {
                        serial_number: this.state.deviceInfo.loginCode,
                        num_times: resp.data[0].num_times,
                        id_negozio: localStorage.getItem("idNegozio"),
                        id_operatore: localStorage.getItem("userId"),
                        id_cliente: this.state.clienteSelezionato.id_cliente,
                        id_tipo_documento: this.state.tipologiaDocumento.value,
                        numero_documento: this.state.numeroDocumento.value,
                        is_coupon: 0,
                        coupon_serial_number: null,
                    };
                    instance.post('/setRicevuta', postData)
                    // axios.post('http://localhost:8989/api/setRicevuta', postData)
                        .then(response => {
                            this.setState({documentoInserito: true})
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }


            } else if (resp.data[0].ret_code === 3) {
                this.setState({
                    piceaData: resp.data[0].ret_message.replaceAll("_", " "),
                    loadingPicea: false,
                    isProcessoPending: true,
                    isNecessariaRicevuta: false,
                    snToDelete: deviceData.serial_number
                })
            } else {
                this.setState({
                    piceaData: resp.data[0].ret_message.replaceAll("_", " "),
                    loadingPicea: false,
                    isProcessoPending: false
                })
            }
        });

    }

    getListaNazioni = () => {
        let postData = {
            id_paese: null
        };
        instance.post('/getNazioni', postData)
        // axios.post('http://localhost:8989/api/getNazioni', postData)
            .then(response => {
                const updatedForm = {
                    ...this.state.nazione
                };
                let newOption = response.data;
                updatedForm.options = newOption;
                this.setState({nazione: updatedForm})
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false});
            });
        this.setState({isCreazioneAnagrafica: false})
    };

    delOldDevice = () => {
        let postData = {
            serial_number: this.state.snToDelete,
        };
        instance.post('/delPendingDevice', postData)
        // axios.post('http://localhost:8989/api/delPendingDevice', postData)
            .then(response => {
                if (response.data[0].ret_code === 0) {
                    this.setState({snToDelete: null, isProcessoPending: false});
                    this.submitHandler();
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({snToDelete: null, isProcessoPending: false});
            });
    };

    checkRicevutaFirmata = () => {
        this.setState({isStampaDocumento: true});
        let postData = {
            serial_number: this.state.piceaData.serial_number,
            num_times: this.state.numTimes,
            id_negozio: localStorage.getItem("idNegozio"),
            id_operatore: localStorage.getItem("userId")
        };
        instance.post('/getRicevuta', postData)
            .then(response => {
                if (response.data[0].firma_privacy !== null) {
                    this.setState({isDocumentoFirmato: true});
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false});
            });
    };

    handleChangeSupervalutazione = (e) => {
        let supervalutazione = e.target.value === "true";
        this.setState({isSupervalutazione: supervalutazione});
    };

    ricevutaPresente = () => {
        this.setState({isRicevutaAcquisita: true, isNecessariaRicevuta: false});
    }

    annullaInserimentoDevice = () => {
        let postData = {
            serial_number: this.state.piceaData.serial_number,
            num_times: this.state.numTimes,
            id_negozio: localStorage.getItem("idNegozio"),
            id_operatore: localStorage.getItem("userId")
        };
        instance.post('/deleteDevicePending', postData)
            .then(response => {
                this.setState({loading: false});
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false});
            });
        const updatedForm = {
            ...this.state.modalitaPagamento
        };
        updatedForm.value = 0;
        const updatedFormDoc = {
            ...this.state.numeroDocumento
        };
        updatedFormDoc.value = "";
        const updatedFormCliente = {
            ...this.state.ricercaAnagrafica
        };
        updatedFormCliente.value = "";
        const updatedFormTipoDoc = {
            ...this.state.tipologiaDocumento
        };
        updatedFormTipoDoc.value = "";
        const updatedFormMarcaManuale = {
            ...this.state.marcaManuale
        };
        updatedFormMarcaManuale.value = "";
        const updatedFormModelloManuale = {
            ...this.state.modelloManuale
        };
        updatedFormModelloManuale.value = "";
        const updatedFormMemoriaManuale = {
            ...this.state.memoriaManuale
        };
        updatedFormMemoriaManuale.value = "";
        const updatedFormImeiManuale = {
            ...this.state.imeiManuale
        };
        updatedFormImeiManuale.value = "";
        this.setState({highestPrice: 0,piceaData: null, modalitaPagamento: updatedForm, clienteSelezionato: null, documentoInserito: false, numeroDocumento: updatedFormDoc, ricercaAnagrafica: updatedFormCliente, tipologiaDocumento: updatedFormTipoDoc, piceaSupervalutazione: null, piceaImei: null, piceaPriceChange: 0, piceaImeiDescription: null, couponTotalPrice: 0, piceaImeiInvalid: false, isNecessariaRicevuta: false, isRicevutaAcquisita: false,  marcaManuale: updatedFormMarcaManuale, modelloManuale: updatedFormModelloManuale, memoriaManuale: updatedFormMemoriaManuale, imeiManuale: updatedFormImeiManuale});
        console.log("Annullamento del telefono");
    };

    generateLoginCode = () => {
        const updatedFormMarcaManuale = {
            ...this.state.marcaManuale
        };
        updatedFormMarcaManuale.value = "";
        const updatedFormModelloManuale = {
            ...this.state.modelloManuale
        };
        updatedFormModelloManuale.value = "";
        const updatedFormMemoriaManuale = {
            ...this.state.memoriaManuale
        };
        updatedFormMemoriaManuale.value = "";
        const updatedFormImeiManuale = {
            ...this.state.imeiManuale
        };
        updatedFormImeiManuale.value = "";
        this.setState({isInserimentoManuale: false, isInserimentoManualeFatto: false, deviceInfo: null, marcaManuale: updatedFormMarcaManuale, modelloManuale: updatedFormModelloManuale, memoriaManuale: updatedFormMemoriaManuale, imeiManuale: updatedFormImeiManuale});
        let postData = {
            profile_name: "SmartPhoneCash STD"
        };
        instance.post('/getPhoneCheckLoginCode', postData)
            .then(response => {
                console.log(response);
                if (response.data.login_code ) {
                    this.setState({loginCode: response.data.login_code})
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false, loginCode: null});
            });
        // this.setState({loading: false, loginCode: 'sue868936'});
    };

    inserimentoManualeDevice = () => {
        this.setState({isInserimentoManuale: true, isInserimentoManualeFatto: false, loginCode: null, deviceInfo: null});
    };

    createRandomString = () => {
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < 9; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    };
    getDeviceInfo = () => {
        this.setState({loading: true, isAccountEliminato: false, deviceMaxPrice: 0, isValutazioneEsteticaDefinita: false, isValutazioneEstetica: false, gradoValutazioneEstetica: 'A'});
        this.annullaImei();
        this.annullaNumeroDoc();
        this.annullaModalitaPagamento();
        let postData = {
            login_code: this.state.loginCode
        };
        instance.post('/getPhoneCheckDeviceInfo', postData)
            .then(response => {
                let id_model = null;
                if (response.data.data != null) {
                    this.setState({deviceInfo: response.data.data});
                    let devicePricePostData = {
                        model_name: this.state.deviceInfo.modelName != '' ? this.state.deviceInfo.modelName.replaceAll(this.state.deviceInfo.make, '') : this.state.deviceInfo.modelNo,
                        brand: this.state.deviceInfo.make,
                        memory: this.state.deviceInfo.memory
                    };
                    instance.post('/getDevicePrice', devicePricePostData)
                        .then(response => {
                            id_model = response.data[0].id_model;
                            let postDataCampagne = {
                                id_model_name: id_model,
                            };
                            instance.post('/getCampagne', postDataCampagne).then(resp => {
                                console.log(resp.data);
                                for (let i in resp.data) {
                                    resp.data[i].validita = new Date(resp.data[i].data_inizio).toLocaleDateString() + " - " + new Date(resp.data[i].data_fine).toLocaleDateString();
                                    let operations = [];
                                    let seleziona = (<AddCircleIcon color="#768828" style={{width: '27px', cursor: 'pointer', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}} onClick={() => this.selezionaSupervalutazione(resp.data[i])}>add_circle</AddCircleIcon> )
                                    operations.push(seleziona);
                                    resp.data[i].azioni = operations;
                                }

                                this.setState({campagne: resp.data})
                            });

                            let deprezzamento = 1;
                            switch (this.state.gradoValutazioneEstetica) {
                                case "A":
                                    deprezzamento = 1;
                                    break;
                                case "A-":
                                    deprezzamento = 0.95;
                                    break;
                                case "B+":
                                    deprezzamento = 0.9;
                                    break;
                                case "B-":
                                    deprezzamento = 0.8;
                                    break;
                                case "C+":
                                    deprezzamento = 0.5;
                                    break;
                                case "C-":
                                    deprezzamento = 0.2;
                                    break;
                            }

                            if (Object.keys(this.state.deviceInfo.failed).length > 0)
                                this.setState({deviceMaxPrice: 2, loading: false, deviceRealPrice: 2});
                            else
                                this.setState({deviceMaxPrice: response.data[0].working_price, deviceRealPrice: response.data[0].working_price * deprezzamento, loading: false})
                        })
                        .catch(error => {
                            console.log(error);
                            let errorDevice = this.state.deviceInfo.make + " " + (this.state.deviceInfo.modelName != '' ? this.state.deviceInfo.modelName.replaceAll(this.state.deviceInfo.make, '') : this.state.deviceInfo.modelNo) + " " + this.state.deviceInfo.memory.replaceAll('.00', '');
                            this.setState({loading: false, deviceInfo: null, deviceInfoError: errorDevice + " NON presente nel database "});
                        });

                } else {
                    this.setState({loading: false, deviceInfoError: 'Nessun report trovato con il login code utilizzato'});
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false, deviceInfo: null});
            });
    };


    setInserimentoManualeDevice = () => {
        this.setState({loading: true, isAccountEliminato: false, deviceMaxPrice: 0, isValutazioneEsteticaDefinita: false, isValutazioneEstetica: false, gradoValutazioneEstetica: 'A'});
        this.annullaImei();
        this.annullaNumeroDoc();
        this.annullaModalitaPagamento();

        let serial = this.createRandomString();

        let deviceManualeInfo = {
            "profileName": "SmartPhoneCash_STD_ITA",
            "memory": this.state.memoriaManuale.value,
            "color": "",
            "pending": {},
            "loginCode": serial,
            "imei2": "N/A",
            "createdAt": "2025-02-14T08:13:57.000Z",
            "blacklistStatus": {
                "blacklistStatus": "Not reported lost or stolen",
            },
            "working": {},
            "warranty": null,
            "carrierLockRawResponse": "N/A",
            "model": null,
            "id": "xxx",
            "make": this.state.marcaManuale.value,
            "modelNo": this.state.modelloManuale.value,
            "modelName": this.state.modelloManuale.value,
            "serial": "xxx",
            "imei": this.state.imeiManuale.value,
            "passed": [],
            "failed": [],
        };
        this.setState({deviceInfo: deviceManualeInfo, deviceMaxPrice: 2, loading: false, deviceRealPrice: 2, isInserimentoManualeFatto: true, isInserimentoManuale: false});
        let postDataCampagne = {
            id_model_name: 0,
        };
        instance.post('/getCampagne', postDataCampagne).then(resp => {
            console.log(resp.data);
            for (let i in resp.data) {
                resp.data[i].validita = new Date(resp.data[i].data_inizio).toLocaleDateString() + " - " + new Date(resp.data[i].data_fine).toLocaleDateString();
                let operations = [];
                let seleziona = (<AddCircleIcon color="#768828" style={{width: '27px', cursor: 'pointer', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}} onClick={() => this.selezionaSupervalutazione(resp.data[i])}>add_circle</AddCircleIcon> )
                operations.push(seleziona);
                resp.data[i].azioni = operations;
            }

            this.setState({campagne: resp.data})
        });
    };

    handleChangeScreen = (e) => {
        const updatedFormScreen = {
            ...this.state.condizioniEstetiche
        };
        updatedFormScreen.screen = e.target.value;
        let max = Math.max.apply(null, Object.values(updatedFormScreen));
        let gradoLabel;
        switch (max) {
            case 0: gradoLabel = "A"; break;
            case 1: gradoLabel = "A-"; break;
            case 2: gradoLabel = "B+"; break;
            case 3: gradoLabel = "B-"; break;
            case 4: gradoLabel = "C+"; break;
            case 5: gradoLabel = "C-"; break;
        }
        this.setState({condizioniEstetiche: updatedFormScreen, gradoValutazioneEstetica: gradoLabel})
    };

    handleChangeBackGlass = (e) => {
        const updatedFormScreen = {
            ...this.state.condizioniEstetiche
        };
        updatedFormScreen.backGlass = e.target.value;
        let max = Math.max.apply(null, Object.values(updatedFormScreen));
        let gradoLabel;
        switch (max) {
            case 0: gradoLabel = "A"; break;
            case 1: gradoLabel = "A-"; break;
            case 2: gradoLabel = "B+"; break;
            case 3: gradoLabel = "B-"; break;
            case 4: gradoLabel = "C+"; break;
            case 5: gradoLabel = "C-"; break;
        }
        this.setState({condizioniEstetiche: updatedFormScreen, gradoValutazioneEstetica: gradoLabel})
    };

    handleChangeFrame = (e) => {
        const updatedFormScreen = {
            ...this.state.condizioniEstetiche
        };
        updatedFormScreen.frame = e.target.value;
        let max = Math.max.apply(null, Object.values(updatedFormScreen));
        let gradoLabel;
        switch (max) {
            case 0: gradoLabel = "A"; break;
            case 1: gradoLabel = "A-"; break;
            case 2: gradoLabel = "B+"; break;
            case 3: gradoLabel = "B-"; break;
            case 4: gradoLabel = "C+"; break;
            case 5: gradoLabel = "C-"; break;
        }
        this.setState({condizioniEstetiche: updatedFormScreen, gradoValutazioneEstetica: gradoLabel})
    };

    handleChangeBackCamera = (e) => {
        const updatedFormScreen = {
            ...this.state.condizioniEstetiche
        };
        updatedFormScreen.backCamera = e.target.value;
        let max = Math.max.apply(null, Object.values(updatedFormScreen));
        let gradoLabel;
        switch (max) {
            case 0: gradoLabel = "A"; break;
            case 1: gradoLabel = "A-"; break;
            case 2: gradoLabel = "B+"; break;
            case 3: gradoLabel = "B-"; break;
            case 4: gradoLabel = "C+"; break;
            case 5: gradoLabel = "C-"; break;
        }
        this.setState({condizioniEstetiche: updatedFormScreen, gradoValutazioneEstetica: gradoLabel})
    };

    copyToCliborard = (link) => {
        navigator.clipboard.writeText(link);
        this.setState({confirmCopy: true})
    };

    handleMenu = (event) => {
        this.setState({anchorEl: event.currentTarget})
    };

    handleCloseMenu = () => {
        this.setState({anchorEl: null})
    };

    render() {

        let authRedirect = null;
        console.log('[Buy-Device] isAuth? ' + this.props.isAuth);

        const StyledCardActionArea = styled(CardActionArea)(({theme}) =>
            `.MuiCardActionArea-focusHighlight {
                background: transparent;
                cursor: pointer;
            }`
        );

        const closeImeiAlertHandler = () => {
            this.annullaImei();
            this.annullaTelefono();
        };

        const ricevutaPresenteHandler = () => {
            this.ricevutaPresente()
        };
        const ricevutaAssenteHandler = () => {
            this.annullaInserimentoDevice();
        };

        const saltaSupervalutazione = () => {
            const updatedForm = {
                ...this.state.tipologiaSupervalutazione
            };
            updatedForm.value = 2;
            this.setState({ tipologiaSupervalutazione: updatedForm, modelNameCampagna: null });
        };


        let piceaForm = (
            <TextField
                id="picea_link"
                label="Inserisci link Picea"
                variant="outlined"
                style={{margin: 10}}
                fullWidth
                value={this.props.piceaLink}
                onChange={(e) => this.inputonChangeHandler(e, "piceaLink")}
            />
        );

        let loginQrCode = null;


        let spinnerPicea = null;
        if (this.state.loadingPicea) {
            spinnerPicea = <Spinner/>
        }
        let spinner = null;
        if (this.state.loading) {
            spinner = <Spinner/>
        }

        let stickyHeader = null;

        if (this.state.deviceInfo != null) {
            stickyHeader = (
                <div className={classes.Sticky} id="myHeader">
                    <h2>{"DEVICE TRADE-IN: " + this.state.deviceInfo.make + " " + (this.state.deviceInfo.modelName != '' ? this.state.deviceInfo.modelName.replaceAll(this.state.deviceInfo.make, '') : this.state.deviceInfo.modelNo) + " " + this.state.deviceInfo.memory.replaceAll('.00', '') + " - VALORE: " + this.state.deviceRealPrice + " €"}</h2>
                </div>
            )
        }

        let processoPending = null;
        if (this.state.isProcessoPending) {
            processoPending = (
                <Auxiliary>
                    <p>Chiudere il processo e permettere l'inserimento del device?</p>
                    <Button clicked={this.delOldDevice}>Si, chiudi il processo e permetti inserimento device</Button>
                </Auxiliary>
            );
        }
        if (this.state.piceaData && this.state.piceaData.brand_name != null) {
            piceaForm = (
                <div>
                    <p>Link picea correttamente inserito per il device: {this.state.piceaData.brand_name + " " + this.state.piceaData.model_name}</p>
                    <p>Valore Acquisto: {this.state.piceaData.valore_acquisto} €</p>
                </div>
            )
        } else if (this.state.piceaData) {
            piceaForm = (
                <Auxiliary>
                    <p>{this.state.piceaData}</p>
                    {processoPending}
                </Auxiliary>)
        }

        let ricevutaNecessaria = null;
        if (this.state.isNecessariaRicevuta) {
            ricevutaNecessaria =  (
                <Dialog
                    open={this.state.isNecessariaRicevuta}
                >
                    <DialogTitle style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
                        <Error style={{color:'red'}}/>
                        ATTENZIONE
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Il dispositivo che si sta acquistando ha una valutazione massima <b>superiore ai 400 €</b><br/>
                            Confermi di essere in possesso della relativa ricevuta d'acquisto?<br/><br/>
                            In caso di assenza della prova d'acquisto il processo verrà interrotto<br/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button clicked={ricevutaPresenteHandler}>
                            SI, è presente la ricevuta d'acquisto
                        </Button>
                        <Button clicked={ricevutaAssenteHandler}>
                            NO, la ricevuta è assente
                        </Button>
                    </DialogActions>
                </Dialog>)
        }

        let piceaSupervalutazione = null;
        if (this.state.piceaSupervalutazione > 0) {
            piceaSupervalutazione = (
                <div>
                    <p>{this.state.piceaImeiDescription } </p>
                </div>
            )
        }

        let cancellazioneForm = null;

        if (this.state.deviceInfo != null && !this.state.isAccountEliminato ) {
            console.log(this.state.deviceInfo);

            let make = this.state.deviceInfo.make.toLowerCase();
            let textApple = null;
            let textGoogle = null;
            let textMake = null;
            if (this.state.deviceInfo.make === 'Apple') {
                textApple = this.state.istruzioniEliminazioneAccount.apple;
                cancellazioneForm = (
                    <Auxiliary>
                        <ModalAccount show={this.state.anchorEl} modalClosed={this.handleCloseMenu} customStyle={"Dialog"} title={"Istruzioni rimozione account"} >
                            <Auxiliary>
                                <p style={{fontSize: 'larger'}}><strong>Istruzioni rimozione dell'account {this.state.deviceInfo.make}:</strong><br />
                                </p>
                                <div dangerouslySetInnerHTML={{ __html: textApple }}>
                                </div>
                            </Auxiliary>
                        </ModalAccount>
                        <Button clicked={() => this.confermaEliminazioneAccount()}>CONFERMA ELIMINAZIONE DEGLI ACCOUNT DEL DISPOSITIVO</Button>
                        <br />
                    </Auxiliary>)
            } else {
                textGoogle = this.state.istruzioniEliminazioneAccount.google;
                textMake = this.state.istruzioniEliminazioneAccount[make];
                cancellazioneForm = (
                    <Auxiliary>
                        <ModalAccount show={this.state.anchorEl} modalClosed={this.handleCloseMenu} customStyle={"Dialog"} title={"Istruzioni rimozione account"} >
                            <Auxiliary>
                                <p style={{fontSize: 'larger'}}><strong>Istruzioni rimozione dell'account Google:</strong><br />
                                </p>
                                <div dangerouslySetInnerHTML={{ __html: textGoogle }}>
                                </div>
                            </Auxiliary>
                        </ModalAccount>
                        <Button clicked={() => this.confermaEliminazioneAccount()}>CONFERMA ELIMINAZIONE DEGLI ACCOUNT DEL DISPOSITIVO</Button>
                        <br />
                    </Auxiliary>);

                if (textMake !== undefined) {
                    cancellazioneForm = (
                        <Auxiliary>
                            <ModalAccount show={this.state.anchorEl} modalClosed={this.handleCloseMenu} customStyle={"Dialog"} title={"Istruzioni rimozione account"} >
                                <Auxiliary>
                                    <p style={{fontSize: 'larger'}}><strong>Istruzioni rimozione dell'account
                                        Google:</strong><br/>
                                    </p>
                                    <div dangerouslySetInnerHTML={{__html: textGoogle}}>
                                    </div>
                                    <br/>
                                    <p style={{fontSize: 'larger'}}><strong>Istruzioni rimozione dell'account {this.state.deviceInfo.make} del
                                        dispositivo:</strong><br/>
                                    </p>
                                    <div dangerouslySetInnerHTML={{__html: textMake}}>
                                    </div>
                                </Auxiliary>
                            </ModalAccount>
                            <Button clicked={() => this.confermaEliminazioneAccount()}>CONFERMA ELIMINAZIONE DEGLI ACCOUNT DEL DISPOSITIVO</Button>
                            <br />
                        </Auxiliary>)
                }
            }
        }

        if (this.state.isAccountEliminato) {
            cancellazioneForm = (
                <div className={classes.PassedTest}>
                    Account dispositivo disattivati
                </div>
            )
        }

        let modalitaPagamento = null;
        if ((this.state.isValutazioneEsteticaDefinita && this.state.deviceInfo != null && this.state.isAccountEliminato) || this.state.isInserimentoManualeFatto ) {
            modalitaPagamento = (
                <div>
                    <p>Seleziona la modalità di pagamento</p>
                    <TextField
                        id="modalitaPagamento"
                        select
                        fullWidth
                        variant="outlined"
                        elementType="select"
                        value={this.state.modalitaPagamento.value}
                        label="Modalità pagamento"
                        onChange={(e) => this.inputonChangeHandler(e, "modalitaPagamento")}
                    >
                        {this.state.modalitaPagamento.options.map((option) => (
                            <MenuItem key={option.key} value={option.key}>
                                {option.displayValue}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            )
        }

        if (this.state.modalitaPagamento.value > 0) {
            modalitaPagamento = (
                <div className={classes.PassedTest}>
                    <p className={classes.Paragrafo}>Modalità pagamento scelta: {this.state.modalitaPagamento.options[this.state.modalitaPagamento.value].displayValue}</p>
                    <br/><Button clicked={() => this.annullaModalitaPagamento()}>ANNULLA</Button>
                </div>
            )
        }

        let formAnagrafica = null;
        if (this.state.modalitaPagamento.value > 0) {
            formAnagrafica = (
                <div>
                    <TextField
                        id="ricercaAnagrafica"
                        elementType="input"
                        variant="outlined"
                        value={this.state.ricercaAnagrafica.value}
                        label="Ricerca anagrafica"
                        style={{margin: 10}}
                        fullWidth
                        helperText={this.state.ricercaAnagrafica.validationError}
                        error={!this.state.ricercaAnagrafica.valid && this.state.ricercaAnagrafica.touched}
                        onChange={(e) => this.inputonChangeHandler(e, "ricercaAnagrafica")}
                    />

                    <Button clicked={(e) => this.openModalRicercaCliente(this.state.ricercaAnagrafica.value)} disabled={!this.state.ricercaAnagrafica.valid || this.state.ricercaAnagrafica === ""}>Ricerca</Button>
                    <h5>oppure</h5>
                    <Button clicked={this.openModalCreazioneAnagrafica}>Inserisci Anagrafica</Button>
                </div>
            )
        }

        let modal = null;
        if (this.state.isRicercaAnagrafica) {
            modal = (
                <Modal show={this.state.isRicercaAnagrafica} modalClosed={this.ricercaCancelHandler} title={"Clienti Trovati"}>
                    {(this.state.loading ? <Spinner/> :
                            <Auxiliary>
                                <TableContainer>
                                    <Table stickyHeader size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nome / Rag. Soc.</TableCell>
                                                <TableCell>C.F. / P. Iva</TableCell>
                                                <TableCell>&nbsp;</TableCell>
                                                <TableCell>&nbsp;</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                (this.state.listaAnagrafiche)
                                                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                                    .map(cliente => (
                                                        <TableRow key={cliente.id_cliente}>
                                                            <TableCell component="th" scope="row">{cliente.cognome === "N.D." ? cliente.ragione_sociale : cliente.cognome + " " + cliente.nome}</TableCell>
                                                            <TableCell>{cliente.codice_fiscale === "N.D." ? cliente.partita_iva : cliente.codice_fiscale}</TableCell>
                                                            <TableCell><Button clicked={() => this.modificaAnagrafica(cliente)}>Modifica</Button></TableCell>
                                                            <TableCell><Button clicked={() => this.selezionaAnagrafica(cliente)}>Scegli</Button></TableCell>
                                                        </TableRow>
                                                    ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[3, 5, 10]}
                                    component="div"
                                    count={this.state.listaAnagrafiche.length - 1}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Auxiliary>
                    )}

                </Modal>
            )
        }

        if (this.state.isCreazioneAnagrafica) {
            const formElementArray = [];
            for (let key in this.state.nuovaAnagrafica) {
                formElementArray.push({
                    id: key,
                    config: this.state.nuovaAnagrafica[key]
                })
            }

            if (this.state.nazioneEsteraChecked) {
                delete formElementArray[8];
                delete formElementArray[9];
            }

            let inputFields = formElementArray.map(el => (
                <TextField
                    name={el.id}
                    key={el.id}
                    label={el.config.elementConfig.placeholder}
                    variant="outlined"
                    value={el.config.value}
                    fullWidth
                    type={el.id == "password" ? "password" : "input" }
                    style={{width: '50%', align: 'center', margin: 10 }}
                    error={!el.config.valid && el.config.touched}
                    helperText={el.config.validationError}
                    touched={el.config.touched}
                    onChange={(e, id) => this.handleChangeAnagrafica(e, el.id)}
                />
            ));

            let sceltaNazioneField = (
                <div>
                    <FormControlLabel
                        control={<Checkbox checked={this.state.nazioneEsteraChecked} onChange={this.handleChangeCheckBox} />}
                        label="Stato Estero?"
                    />
                    <br/>
                    {this.state.nazioneEsteraChecked ? (
                        <TextField
                            id="modalitaPagamento"
                            select
                            fullWidth
                            variant="outlined"
                            elementType="select"
                            value={this.state.nazione.value}
                            label="Nazione"
                            style={{width: '50%', align: 'center', margin: 10 }}
                            // options={this.state.modalitaPagamento.options}
                            onChange={(e) => this.inputonChangeHandler(e, "nazione")}
                        >
                            {this.state.nazione.options.map((option) => (
                                <MenuItem key={option.sigla_nazione} value={option.sigla_nazione}>
                                    {option.paese}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : null}
                </div>
            );

            modal = (
                <Modal show={this.state.isCreazioneAnagrafica} modalClosed={this.ricercaCancelHandler} title={"Creazione Anagrafica"} customStyle={"Creazione Anagrafica"}>
                    <div style={{columns: 2}}>
                        {sceltaNazioneField}
                        {inputFields}
                    </div>
                    <Button clicked={this.creaAnagrafica} disabled={!this.state.formAnagraficaIsValid}>Crea Anagrafica</Button>
                </Modal>
            )
        }

        if (this.state.isModificaAnagrafica) {
            const formElementArray = [];
            for (let key in this.state.nuovaAnagrafica) {
                formElementArray.push({
                    id: key,
                    config: this.state.nuovaAnagrafica[key]
                })
            }
            let inputFields = formElementArray.map(el => (
                <TextField
                    name={el.id}
                    key={el.id}
                    label={el.config.elementConfig.placeholder}
                    variant="outlined"
                    value={this.state.clienteDaModificare[el.id]}
                    type={el.id === "password" ? "password" : "input" }
                    style={{width: '50%', align: 'center', margin: 10 }}
                    error={!el.config.valid && el.config.touched}
                    helperText={el.config.validationError}
                    touched={el.config.touched}
                    onChange={(e) => this.handleChangeModifica(e)}
                />
            ));


            modal = (
                <Modal show={this.state.isModificaAnagrafica} modalClosed={this.ricercaCancelHandler} title={"Modifica Anagrafica"} customStyle={"Creazione Anagrafica"}>
                    <div style={{columns: 2}}>
                        {inputFields}
                    </div>
                    <Button clicked={this.creaAnagrafica}>Modifica</Button>
                </Modal>
            )
        }

        let infoCliente = null;
        if (this.state.clienteSelezionato !== null) {
            formAnagrafica = null;
            infoCliente = (
                <div className={classes.PassedTest}>
                    <p className={classes.Paragrafo}>{this.state.clienteSelezionato.cognome + " " + this.state.clienteSelezionato.nome}</p>
                    <Divider />
                    <p className={classes.Paragrafo}>{this.state.clienteSelezionato.codice_fiscale}</p>
                    <Divider />
                    <p className={classes.Paragrafo}>{this.state.clienteSelezionato.indirizzo + " " + this.state.clienteSelezionato.numero_civico + ", " + this.state.clienteSelezionato.citta}</p>
                    <br/><Button clicked={() => this.annullaCliente()}>ANNULLA</Button>
                </div>
            );
        }

        let tipologiaDocumento = null;
        if (this.state.clienteSelezionato !== null) {
            tipologiaDocumento = (
                <div>
                    <p>Seleziona la tipologia di documento</p>
                    <TextField
                        id="tipologiaDocumento"
                        elementType="select"
                        select
                        fullWidth
                        variant="outlined"
                        value={this.state.tipologiaDocumento.value}
                        label="Tipologia Documento Identificazione"
                        options={this.state.tipologiaDocumento.options}
                        onChange={(e) => this.inputonChangeHandler(e, "tipologiaDocumento")}
                    >
                        {this.state.tipologiaDocumento.options.map((option) => (
                            <MenuItem key={option.key} value={option.key}>
                                {option.displayValue}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            )
        }

        if (this.state.tipologiaDocumento.value > 0) {
            tipologiaDocumento = (
                <div className={classes.PassedTest}>
                    <p className={classes.Paragrafo}>Documento identificazione: {this.state.tipologiaDocumento.options[this.state.tipologiaDocumento.value].displayValue}</p>
                    <br/><Button clicked={() => this.annullaDoc()}>ANNULLA</Button>
                </div>
            )
        }

        let numeroDocumento = null;
        if (this.state.tipologiaDocumento.value > 0) {
            numeroDocumento = (
                <div>
                    <p>Inserisci il numero del documento</p>
                    <TextField
                        id="numeroDocumento"
                        elementType="input"
                        fullWidth
                        value={this.state.numeroDocumento.value}
                        label="Numero Documento"
                        variant="outlined"
                        style={{margin: 10}}
                        onChange={(e) => this.inputonChangeHandler(e, "numeroDocumento")}
                    />
                    <Button clicked={this.confermaDocumentoPhoneCheck} disabled={this.state.numeroDocumento.value.length < 7}>Conferma Documento</Button>
                </div>
            )
        }

        if (this.state.documentoInserito) {
            tipologiaDocumento = null;
            numeroDocumento = (
                <div className={classes.PassedTest}>
                    <p className={classes.Paragrafo}>Documento identificazione: {this.state.tipologiaDocumento.options[this.state.tipologiaDocumento.value].displayValue} {this.state.numeroDocumento.value.toLocaleUpperCase()}</p>
                    <br/><Button clicked={() => this.annullaNumeroDoc()}>ANNULLA</Button>
                </div>
            )
        }

        const newColumnsCampagne = [
            {accessorKey: 'id_campagna', header: 'Campagna', editable: false, size: 100},
            {accessorKey: 'sku', header: 'SKU', editable: false, size: 100},
            {accessorKey: 'model_name_campagna', header: 'Device supervalutazione', editable: false, size: 100},
            {accessorKey: 'supervalutazione', header: 'Valore', editable: false, size: 80, Cell: ({ cell }) => cell.getValue() + " €"},
            {accessorKey: 'validita', header: 'Validità', editable: false, size: 100},
            {accessorKey: 'azioni', header: 'Seleziona', editable: false}
        ];

        let supervalutazione = null;

        if (this.state.documentoInserito && this.state.tipologiaSupervalutazione.value === 0 && this.state.isAffiliatoAttivoSupervalutazione && this.state.modalitaPagamento.value === 1) {
            supervalutazione = (
                <Auxiliary>
                    <Box style={{textAlign: '-webkit-center', display: 'inline-block', width: '100%'}}>
                        <Box className={classes.ResultBox}>
                            <h3 className={classes.FiltriTitle}>Campagne attive per il dispositivo </h3>
                            <MaterialReactTable
                                columns={newColumnsCampagne}
                                data={this.state.campagne}
                                enableColumnActions={false}
                                enableColumnFilters={false}
                                enablePagination={false}
                                enableSorting={false}
                                enableBottomToolbar={false}
                                enableTopToolbar={true}
                                initialState={{ columnPinning: { right: ['azioni'] }, density: 'compact'}}
                                muiTableBodyRowProps={{ hover: false }}
                            />
                        </Box>
                        <Button
                            color="primary"
                            clicked={() => saltaSupervalutazione()}
                            variant="contained"
                        >{'SALTA SUPERVALUTAZIONE'}
                        </Button>
                    </Box>
                </Auxiliary>
            )
        }

        let imeiFieldSupervalutazione = (
            <TextField
                id="imeiSupervalutazione"
                elementType="input"
                fullWidth
                value={this.state.imeiSupervalutazione.value}
                label="IMEI Telefono Acquistato"
                variant="outlined"
                style={{margin: 10}}
                helperText={this.state.datiSupervalutazione.imei === 1 ? "Campo obbligatorio - Lughezza 15 caratteri" : ""}
                onChange={(e) => this.inputonChangeHandler(e, "imeiSupervalutazione")}
            />
        );

        let skuSupervalutazione = (
            <TextField
                id="skuSupervalutazione"
                elementType="input"
                fullWidth
                value={this.state.skuSupervalutazione.value}
                label="SKU Telefono Acquistato"
                variant="outlined"
                style={{margin: 10}}
                helperText={this.state.datiSupervalutazione.serial === 1 ? "Campo obbligatorio" : ""}
                onChange={(e) => this.inputonChangeHandler(e, "skuSupervalutazione")}
            />
        );

        let eanSupervalutazione = (
            <TextField
                id="eanSupervalutazione"
                elementType="input"
                fullWidth
                value={this.state.eanSupervalutazione.value}
                label="EAN Telefono Acquistato"
                variant="outlined"
                style={{margin: 10}}
                helperText={this.state.datiSupervalutazione.ean === 1 ? "Campo obbligatorio" : ""}
                onChange={(e) => this.inputonChangeHandler(e, "eanSupervalutazione")}
            />
        );

        let imeiSupervalutazione = null;
        if (this.state.tipologiaSupervalutazione.value === 1 && !this.state.supervalutazioneInserita) {

            let buttonDisabled =
                (this.state.imeiSupervalutazione.value.length !== 15 && this.state.datiSupervalutazione.imei === 1) ||
                (this.state.skuSupervalutazione.value.length < 6 && this.state.datiSupervalutazione.serial === 1) ||
                (this.state.eanSupervalutazione.value.length < 10 && this.state.datiSupervalutazione.ean === 1);

            if (this.state.datiSupervalutazione.imei === 1 && this.state.imeiSupervalutazione.value.length === 15) {
                buttonDisabled = buttonDisabled || !(/^\d+$/.test(this.state.imeiSupervalutazione.value))
            }

            imeiSupervalutazione = (
                <Auxiliary>
                    <div>
                        <div className={classes.PassedTest}>
                            <p className={classes.Paragrafo}>Campagna valida per : {this.state.modelNameCampagna}  </p>
                            <p className={classes.Paragrafo}>Valore supervalutazione: {this.state.valoreSupervalutazione} €</p>
                            <br/><Button clicked={() => this.annullaImei()}>ANNULLA INSERIMENTO SUPERVALUTAZIONE</Button>
                        </div>
                        <br />
                        {this.state.datiSupervalutazione.imei === 1 ? imeiFieldSupervalutazione : null}
                        {this.state.datiSupervalutazione.serial === 1 ? skuSupervalutazione : null}
                        {this.state.datiSupervalutazione.ean === 1 ? eanSupervalutazione : null}
                        <br />
                        <Button clicked={this.confermaSupervalutazione} disabled={buttonDisabled}>Conferma</Button>
                    </div>
                </Auxiliary>
            )
        }

        let imeiNonValido = null;
        if (this.state.piceaImeiInvalid) {
            imeiNonValido = (
                <Dialog
                    open={this.state.piceaImeiInvalid}
                >
                    <DialogTitle style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
                        <Error style={{color:'red'}}/>
                        ATTENZIONE
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            L'IMEI del telefono acquistato non è valido per la promozione<br/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button clicked={closeImeiAlertHandler} autoFocus>
                            CHIUDI
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        }

        let imeiInserito = null;
        if (this.state.supervalutazioneInserita) {
            imeiInserito =
                <Auxiliary>
                    {imeiNonValido}
                    <div className={classes.PassedTest}>
                        <p className={classes.Paragrafo}>Campagna valida per : {this.state.modelNameCampagna} </p>
                        {/*<p style={{fontSize: 'x-large'}}>Valore supervalutazione: {this.state.valoreSupervalutazione} €</p>*/}
                        {/*<p style={{fontSize: 'x-large'}}>IMEI telefono acquistato: {this.state.imeiSupervalutazione.value.toLocaleUpperCase()}</p>*/}
                        <br/><Button clicked={() => this.annullaImei()}>ANNULLA INSERIMENTO IMEI</Button>
                    </div>
                </Auxiliary>
        }

        let qrCode = null;
        let buttonStampaRicevuta = null;
        let riassuntoPermuta = null;

        let showQrCode = (this.state.supervalutazioneInserita && this.state.documentoInserito) || ((this.state.tipologiaSupervalutazione.value === 2 && this.state.documentoInserito)) || (!this.state.isAffiliatoAttivoSupervalutazione && this.state.documentoInserito);
        if (showQrCode && !this.state.piceaImeiInvalid) {

            riassuntoPermuta = (
                <div className={classes.PassedTest}>
                    <p className={classes.Paragrafo}>VALORE FINALE DISPOSITIVO: {this.state.deviceRealPrice} € </p>
                </div>
            )

            qrCode = (
                <div style={{marginTop: '1%'}} className={classes.PassedTest}>
                    <QRCode
                        value={"https://collaudo.smartphonecash.it/takePhoto?cf=" + this.state.clienteSelezionato.codice_fiscale + "&device=" + this.state.piceaData.serial_number + "&negozio=" + localStorage.getItem("idNegozio") + "&idc=" + this.state.clienteSelezionato.id_cliente + "&nt=" + this.state.numTimes}/>
                    <p>Inquadra il QR Code dal cellulare per scattare le foto al documento</p>
                    <Button clicked={() => this.copyToCliborard("https://collaudo.smartphonecash.it/takePhoto?cf=" + this.state.clienteSelezionato.codice_fiscale + "&device=" + this.state.piceaData.serial_number + "&negozio=" + localStorage.getItem("idNegozio") + "&idc=" + this.state.clienteSelezionato.id_cliente + "&nt=" + this.state.numTimes)}>Copia Link</Button>
                </div>
            );

            buttonStampaRicevuta = (
                <Button clicked={this.checkRicevutaFirmata}>STAMPA RICEVUTA FIRMATA</Button>
            )
        }
        let confermaDocumento = null;
        if (this.state.isStampaDocumento && this.state.isDocumentoFirmato) {
            const codDocumento = this.state.piceaData.serial_number + "-" + this.state.numTimes;
            window.open("https://collaudo.smartphonecash.it/stampaRicevuta?cod=" + codDocumento);

            confermaDocumento = (
                <Snackbar open={true} closed={this.handleClose} severity="success">Ricevuta firmata correttamente!</Snackbar>
            )
        } else if (this.state.isStampaDocumento && !this.state.isDocumentoFirmato) {
            confermaDocumento = (
                <Snackbar open={true} closed={this.handleClose}  severity="error">La ricevuta digitale non è ancora stata completata</Snackbar>
            )
        }

        let confirmCopy = (
            <Snackbar open={this.state.confirmCopy} autoHideDuration={2000} closed={this.handleClose} severity="success">Link copiato negli appunti!</Snackbar>
        );

        let riceviValutazioneDisposiziono = null;
        if (this.state.deviceInfo != null && !this.state.isValutazioneEsteticaDefinita && this.state.deviceInfoError == null) {
            riceviValutazioneDisposiziono = (
                <div>
                    <Button clicked={this.openModalCondizioniEstetiche}>Valutazione grado estetico device</Button>
                    <br />
                </div>
            )
        };


        let deviceInfoForm = null;
        let passedTest = [];
        let failedTest = [];
        let deviceName = null;
        let batteryPercentage = null;
        let batteryPercentageFormatted = null;
        let passedTestFormatted = null;
        let failedTestFormatted = null;
        let deviceMaxPrice = null;
        let valutazioneEstetica = null;
        let deprezzamento = 1;
        let blacklist = false;
        let blacklistInfo = null;
        if (this.state.isValutazioneEsteticaDefinita) {
            switch (this.state.gradoValutazioneEstetica) {
                case "A":
                    deprezzamento = 1;
                    break;
                case "A-":
                    deprezzamento = 0.95;
                    break;
                case "B+":
                    deprezzamento = 0.9;
                    break;
                case "B-":
                    deprezzamento = 0.8;
                    break;
                case "C+":
                    deprezzamento = 0.5;
                    break;
                case "C-":
                    deprezzamento = 0.2;
                    break;
                default :
                    deprezzamento = 1;
            }
            if (this.state.deviceInfoError == null) valutazioneEstetica = (<div className={classes.PassedTest}><span>{"VALUTAZIONE ESTETICA: GRADO " + this.state.gradoValutazioneEstetica}</span></div>);
        }

        if (this.state.deviceInfo != null) {
            deviceName = (<p>{this.state.deviceInfo.make + " " + (this.state.deviceInfo.modelName != '' ? this.state.deviceInfo.modelName.replaceAll(this.state.deviceInfo.make, '') : this.state.deviceInfo.modelNo) + " " + this.state.deviceInfo.memory.replaceAll('.00', '')}</p>);
            passedTest = Object.keys(this.state.deviceInfo.passed);
            delete this.state.deviceInfo.failed['Manual Battery Health'];
            failedTest = Object.keys(this.state.deviceInfo.failed);

            deviceMaxPrice = (<p>{"VALUTAZIONE DISPOSITIVO: € " + (failedTest.length > 0 ? 2 : this.state.deviceMaxPrice * deprezzamento)}</p>);
            // this.setDeviceMaxPrice(failedTest.length > 0 ? 2 : this.state.deviceMaxPrice * deprezzamento);
            // batteryPercentage = this.state.deviceInfo.BatteryResults.batteryHealthPercentage.replaceAll(' %', '');
            // batteryPercentageFormatted = <Auxiliary><div className={batteryPercentage > 80 ? classes.PassedTest : classes.FailedTest}><span>{"Battery Health " + batteryPercentage + "%"}</span></div></Auxiliary>;

            if (passedTest.length === 0) {
                passedTestFormatted =  (
                    <Auxiliary><div className={classes.ManualTest}><span>Il dispositivo non ha effettuato i test</span></div></Auxiliary>
                );
            } else {

                passedTestFormatted = passedTest.map((item, key) => (
                    <Auxiliary>
                        <div className={classes.PassedTest}><span>{item}</span></div>
                    </Auxiliary>
                ));
            }
            failedTestFormatted = failedTest.map((item, key) => (
                <Auxiliary><div className={classes.FailedTest}><span>{item}</span></div></Auxiliary>
            ));
            blacklist = this.state.deviceInfo.blacklistStatus.blacklistStatus !== 'Not reported lost or stolen';
            if (blacklist)
                blacklistInfo = (<Auxiliary><div className={classes.FailedTest}><span>IMEI PRESENTE IN BLACKLIST!!</span></div></Auxiliary>);
            else
                blacklistInfo = (<Auxiliary><div className={passedTest.length === 0 ? classes.ManualTest : classes.PassedTest}><span>L'IMEI NON RISULTA PRESENTE IN NESSUNA BLACKLIST</span></div></Auxiliary>)
        } else if (this.state.deviceInfoError != null) {
            deviceName = this.state.deviceInfoError;
        }

        if (this.state.deviceInfo != null || this.state.isValutazioneEsteticaDefinita) {
            deviceInfoForm = (
                <Auxiliary>
                    <Box style={{textAlign: 'center'}}>
                        <Box className={classes.ResultBox}>
                            <h3 className={classes.TestTitle}>
                                {deviceName}
                                {deviceMaxPrice}
                                {/*{valutazioneEstetica}*/}
                            </h3>
                            <div>
                                <Box className={classes.ResultBoxSmall}>
                                    <h3 className={classes.TestTitle}>ESITI TEST</h3>
                                    <div style={{display: 'inline-block', width: '100%', columns: this.state.isInserimentoManualeFatto ? 1 : 2, maxHeight: '500px'}}>

                                        {failedTestFormatted}
                                        {/*{batteryPercentageFormatted}*/}
                                        {passedTestFormatted}
                                    </div>
                                </Box>
                                <Box className={classes.ResultBoxSmall}>
                                    <div style={{display: 'inline-block', width: '100%', verticalAlign: 'top'}}>
                                        <h3 className={classes.TestTitle}>IMEI BLACKLIST</h3>
                                        {blacklistInfo}
                                    </div>
                                    <br />
                                    <Divider />
                                    <br />
                                    <div style={{display: 'inline-block', width: '100%', verticalAlign: 'top'}}>
                                        <h3 className={classes.TestTitle}>VALUTAZIONE ESTETICA</h3>
                                        {riceviValutazioneDisposiziono}
                                        {valutazioneEstetica}
                                    </div>
                                    <br />
                                    <Divider />
                                    <br />
                                    <div style={{display: 'inline-block', width: '100%', verticalAlign: 'top'}}>
                                        <h3 className={classes.TestTitle}>ACCOUNT
                                            <IconButton
                                                onClick={(event) => this.handleMenu(event)}
                                                size="small"
                                                sx={{ ml: 2 }}
                                                aria-controls={this.state.anchorEl ? 'account-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={this.state.anchorEl ? 'true' : undefined}
                                            >
                                                <Avatar sx={{ width: 32, height: 32 }}>?</Avatar>
                                            </IconButton>
                                        </h3>

                                        {cancellazioneForm}
                                    </div>
                                </Box>
                            </div>
                        </Box>
                    </Box>
                </Auxiliary>
            )
        }

        if (this.state.deviceInfoError != null) {
            deviceInfoForm = (
                <Auxiliary>
                    <Box style={{textAlign: 'center'}}>
                        <Box className={classes.ResultBox}>
                            <h3 className={classes.ErrorTitle}>
                                {deviceName}
                            </h3>
                        </Box>
                    </Box>
                </Auxiliary>
            )
        }

        let modalCondizioniEstetiche =  (
            <Modal show={this.state.isValutazioneEstetica} modalClosed={this.valutazioneEsteticaCloseHandler} title={"Valutazione Estetica"} customStyle={"Creazione Anagrafica"}>
                <div>
                    <Card elevation={0} className={classes.Side} variant="outlined">
                        <CardActionArea>
                            <CardContent>
                                <FormControl>
                                    <FormLabel className={classes.UndexText}><strong>Screen</strong></FormLabel>
                                    <RadioGroup
                                        value={this.state.condizioniEstetiche.screen}
                                        onChange={this.handleChangeScreen}
                                    >
                                        <FormControlLabel className={classes.RadioLabel} value="0" control={<Radio />} label="Come Nuovo" />
                                        <FormControlLabel className={classes.RadioLabel} value="1" control={<Radio />} label="No Graffi" />
                                        <FormControlLabel className={classes.RadioLabel} value="2" control={<Radio />} label="Graffi -1" />
                                        <FormControlLabel className={classes.RadioLabel} value="3" control={<Radio />} label="Graffi +1" />
                                        <FormControlLabel className={classes.RadioLabel} value="4" control={<Radio />} label="Incisioni -1" />
                                        <FormControlLabel className={classes.RadioLabel} value="5" control={<Radio />} label="Incisioni +1" />
                                    </RadioGroup>
                                </FormControl>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <Card elevation={0} className={classes.Side} variant="outlined">
                        <CardActionArea >
                            <CardContent>
                                <FormControl>
                                    <FormLabel className={classes.UndexText}><strong>Back Glass</strong></FormLabel>
                                    <RadioGroup
                                        value={this.state.condizioniEstetiche.backGlass}
                                        onChange={this.handleChangeBackGlass}
                                    >
                                        <FormControlLabel className={classes.RadioLabel} value="0" control={<Radio />} label="Come Nuovo" />
                                        <FormControlLabel className={classes.RadioLabel} value="1" control={<Radio />} label="No Graffi" />
                                        <FormControlLabel className={classes.RadioLabel} value="2" control={<Radio />} label="Graffi -1" />
                                        <FormControlLabel className={classes.RadioLabel} value="3" control={<Radio />} label="Graffi +1" />
                                        <FormControlLabel className={classes.RadioLabel} value="4" control={<Radio />} label="Incisioni -1" />
                                        <FormControlLabel className={classes.RadioLabel} value="5" control={<Radio />} label="Incisioni +1" />
                                    </RadioGroup>
                                </FormControl>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <Card elevation={0} className={classes.Side} variant="outlined" >
                        <CardActionArea >
                            <CardContent>
                                <FormControl>
                                    <FormLabel className={classes.UndexText}><strong>Frame</strong></FormLabel>
                                    <RadioGroup
                                        value={this.state.condizioniEstetiche.frame}
                                        onChange={this.handleChangeFrame}
                                    >
                                        <FormControlLabel className={classes.RadioLabel} value="0" control={<Radio />} label="Come Nuovo" />
                                        <FormControlLabel className={classes.RadioLabel} value="1" control={<Radio />} label="No Graffi" />
                                        <FormControlLabel className={classes.RadioLabel} value="2" control={<Radio />} label="Graffi -1" />
                                        <FormControlLabel className={classes.RadioLabel} value="3" control={<Radio />} label="Graffi +1" />
                                        <FormControlLabel className={classes.RadioLabel} value="4" control={<Radio />} label="Incisioni -1" />
                                        <FormControlLabel className={classes.RadioLabel} value="5" control={<Radio />} label="Incisioni +1" />
                                    </RadioGroup>
                                </FormControl>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <Card elevation={0} className={classes.Side} variant="outlined">
                        <CardActionArea >
                            <CardContent>
                                <FormControl>
                                    <FormLabel className={classes.UndexText}><strong>Back Camera</strong></FormLabel>
                                    <RadioGroup
                                        value={this.state.condizioniEstetiche.backCamera}
                                        onChange={this.handleChangeBackCamera}
                                    >
                                        <FormControlLabel className={classes.RadioLabel} value="0" control={<Radio />} label="Come Nuovo" />
                                        <FormControlLabel className={classes.RadioLabel} value="1" control={<Radio />} label="No Graffi" />
                                        <FormControlLabel className={classes.RadioLabel} value="2" control={<Radio />} label="Graffi -1" />
                                        <FormControlLabel className={classes.RadioLabel} value="3" control={<Radio />} label="Graffi +1" />
                                        <FormControlLabel className={classes.RadioLabel} value="4" control={<Radio />} label="Incisioni -1" />
                                        <FormControlLabel className={classes.RadioLabel} value="5" control={<Radio />} label="Incisioni +1" />
                                    </RadioGroup>
                                </FormControl>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
                <Box className={classes.GradoEsteticoBox}>
                    <div>
                        <strong className={classes.UndexText}>{"GRADO ESTETICO: " + (this.state.gradoValutazioneEstetica)}</strong>
                    </div>
                </Box>
                <Button clicked={this.setValutazioneEstetica} style={{marginTop: '5px'}}>Conferma valutazione estetica</Button>
            </Modal>
        );

        let boxInfoCliente = null;
        if (this.state.isValutazioneEsteticaDefinita || this.state.isInserimentoManualeFatto) {
            boxInfoCliente = (
                <Box className={classes.ResultBox}>
                    {/*{cancellazioneForm}*/}
                    {modalitaPagamento}
                    {confermaDocumento}
                    {confirmCopy}
                    {infoCliente}
                    {tipologiaDocumento}
                    {numeroDocumento}
                    {formAnagrafica}
                </Box>)
        }

        let boxInserimentoManuale = null;

        let buttonInserimentoManualeDisabled = (this.state.imeiManuale.value.length !== 15 ||
            (this.state.marcaManuale.value === undefined || this.state.marcaManuale.value === '') ||
            (this.state.modelloManuale.value === undefined || this.state.modelloManuale.value === '') ||
            (this.state.memoriaManuale.value === undefined || this.state.memoriaManuale.value === ''));
        if (this.state.imeiManuale.value.length === 15) {
            buttonInserimentoManualeDisabled = buttonInserimentoManualeDisabled && !(/^\d+$/.test(this.state.imeiSupervalutazione.value));
        }
        if (this.state.isInserimentoManuale) {
            boxInserimentoManuale = (<Box className={classes.ResultBox}>
                <Auxiliary>
                    <p style={{fontSize: 'larger'}}><strong>Inserimento manuale del device:</strong><br />
                        Compilare tutti i campi e cliccare su "Inserisci informazioni device" per proseguire
                    </p>
                    <Divider />
                    <div>
                        <TextField
                            id="marcaManuale"
                            // select
                            fullWidth
                            variant="outlined"
                            elementType="input"
                            value={this.state.marcaManuale.value}
                            label="Marca Dispositivo"
                            style={{width: '50%', align: 'center', margin: 10 }}
                            onChange={(e) => this.inputonChangeHandler(e, "marcaManuale")}
                        >
                        </TextField>
                    </div>
                    < br/>
                    <div>
                        <TextField
                            id="modelloManuale"
                            elementType="input"
                            fullWidth
                            value={this.state.modelloManuale.value}
                            label="Modello Telefono"
                            variant="outlined"
                            style={{width: '50%', align: 'center', margin: 10}}
                            onChange={(e) => this.inputonChangeHandler(e, "modelloManuale")}
                        />
                    </div>
                    < br/>
                    <div>
                        <TextField
                            id="imeiManuale"
                            elementType="input"
                            fullWidth
                            value={this.state.imeiManuale.value}
                            label="IMEI Telefono"
                            variant="outlined"
                            style={{width: '50%', align: 'center', margin: 10}}
                            onChange={(e) => this.inputonChangeHandler(e, "imeiManuale")}
                        />
                    </div>
                    < br/>
                    <div>
                        <TextField
                            id="memoriaManuale"
                            select
                            fullWidth
                            variant="outlined"
                            elementType="select"
                            value={this.state.memoriaManuale.value}
                            label="Memoria Dispositivo"
                            style={{width: '50%', align: 'center', margin: 10 }}
                            onChange={(e) => this.inputonChangeHandler(e, "memoriaManuale")}
                        >
                            {this.state.memoriaManuale.options.map((option) => (
                                <MenuItem key={option.memory} value={option.memory}>
                                    {option.memory}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    < br/>
                    <div>
                        <Button clicked={this.setInserimentoManualeDevice} disabled={buttonInserimentoManualeDisabled}>INSERISCI INFORMAZIONI DEVICE</Button>
                    </div>
                </Auxiliary>

            </Box>)
        }

        if (this.state.isInserimentoManualeFatto)
            boxInserimentoManuale = (
                <Auxiliary>
                    {deviceInfoForm}
                    {modalCondizioniEstetiche}
                    {/*{riceviValutazioneDisposiziono}*/}
                    {modal}
                    {boxInfoCliente}
                    {supervalutazione}
                    {imeiSupervalutazione}
                    {imeiInserito}
                    {riassuntoPermuta}
                    {qrCode}
                </Auxiliary>)

        if (this.state.loginCode)
            loginQrCode = (
                <Box className={classes.ResultBox}>
                    <Auxiliary>
                        <p style={{fontSize: 'larger'}}><strong>Avviare l'applicazione di test:</strong><br />
                            Dopo l'installazione, apri l'applicazione di test e scansiona il codice QR generato cliccando il pulsante qui sopra.
                        </p>
                        <Divider />
                        <div>
                            <Card elevation={0} className={classes.AppDownload}>
                                <CardContent className={classes.CardLabel}>
                                    <strong className={classes.UndexText}>{"Login Code: " + (this.state.loginCode)}</strong>
                                </CardContent>
                                <StyledCardActionArea>
                                    <CardMedia style={{height: '180px'}}>
                                        <QRCode value={this.state.loginCode}/>
                                    </CardMedia>
                                </StyledCardActionArea>
                            </Card>
                        </div>
                        <div>
                            <Button clicked={this.getDeviceInfo}>RICEVI VALUTAZIONE DISPOSITIVO</Button>
                        </div>
                    </Auxiliary>
                    {spinner}
                    {deviceInfoForm}
                    {modalCondizioniEstetiche}
                    {/*{riceviValutazioneDisposiziono}*/}
                    {modal}
                    {boxInfoCliente}
                    {supervalutazione}
                    {imeiSupervalutazione}
                    {imeiInserito}
                    {riassuntoPermuta}
                    {qrCode}
                </Box>
            );


        let phoneCheckAppDownload = (
            <Box style={{textAlign: 'center'}}>
                <Box className={classes.ResultBox}>
                    <p style={{fontSize: 'larger'}}><strong>Installare l'applicazione di test:</strong><br />
                        Inquadra il QR code con il dispositivo da testare e segui le istruzioni per installare l'applicazione di test.
                    </p>
                    <Divider />
                    <div>
                        <Card elevation={0} className={classes.AppDownload}>
                            <CardContent className={classes.CardLabel}>
                                <strong className={classes.UndexText}>{('Apple App Store')}</strong>
                            </CardContent>
                            <StyledCardActionArea>
                                <CardMedia style={{height: '180px'}}>
                                    <QRCode value={"https://apps.apple.com/gb/app/phonecheck/id1446390777"}/>
                                </CardMedia>
                            </StyledCardActionArea>
                        </Card>
                        <Card elevation={0} className={classes.AppDownload}>
                            <CardContent className={classes.CardLabel}>
                                <strong className={classes.UndexText}>{('Google Play Store')}</strong>
                            </CardContent>
                            <StyledCardActionArea>
                                <CardMedia style={{height: '180px'}}>
                                    <QRCode value={"https://play.google.com/store/apps/details?id=com.phonecheck.phonecheckconsumer&hl=en_US&gl=US"}/>
                                </CardMedia>
                            </StyledCardActionArea>
                        </Card>
                    </div>
                </Box>
                <Button clicked={this.generateLoginCode}>GENERA CODICE DI LOGIN</Button>
                <span style={{margin: '0px 5px 0px 5px'}}>oppure&nbsp;</span>
                <Button clicked={this.inserimentoManualeDevice}>INSERISCI MANUALMENTE DEVICE</Button>
                {loginQrCode}
            </Box>
        );


        return (
            <Auxiliary className={classes.BuyDevice}>
                {stickyHeader}
                <div className={classes.BuyDevice}>
                    {authRedirect}
                    <h1 className={classes.Title}> ACQUISTO DEVICE </h1>
                </div>
                <form onSubmit={e => e.preventDefault()}>
                    <div className={classes.BuyDevice}>
                        {phoneCheckAppDownload}
                        {boxInserimentoManuale}
                        {piceaSupervalutazione}
                        {spinnerPicea}
                        {ricevutaNecessaria}
                    </div>
                    <div className={classes.BuyDevice}>
                        {buttonStampaRicevuta}
                    </div>
                </form>
            </Auxiliary>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState()),
        onCheckLinkPicea: (event) => dispatch(actions.inserimentoPicea(event)),
        onResetLinkPicea: () => dispatch(actions.resetLinkPicea())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        // authRedirectPath: "/buy-device",
        piceaData: state.buyDevice.piceaData,
        negozio: state.auth.negozio
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(BuyDevice);
