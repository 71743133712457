import React, {Component} from "react";
import instance from "../../axios-spc";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import classes from "./PriceCheck.css";
import Box from "@mui/material/Box";
import {Redirect} from "react-router-dom";
import Button from "../../components/UI/Button/Button"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {MaterialReactTable} from "material-react-table";
import AddCircleIcon from '@mui/icons-material/AddCircle';


class PriceCheck extends Component {

    state = {
        brands: [],
        modelli: [],
        memoria: [],
        priceData: [],
        priceSvalutazione: [],
        idBrandSelezionato: null,
        idModelSelezionato: null,
        idMemoriaSelezionato: null,
        loading: false,
        isRicercaPrezzo: false,
        campagne: [],
        reclamoUno: null,
        reclamoDue: null,
        valoreSupervalutazione: null,
        gradoEstetico: null,
        valoreGradoEstetico: null,
        prezzoCompleto: 0,
        supervalutazioneScelta: null,
        gradoEsteticoScelto: null,
        rowSelection: [],
        rowSelectionSupervalutazione: []

    };

    componentDidMount() {
        this.getBrands();
    }

    getBrands = () => {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
        };
        instance.post('/getPriceCheckBrands', postData).then(resp => {
            console.log(resp);
            let result = resp.data.map( el => ({key: el.id_brand, value: el.id_brand, label: el.brand_name}) );
            this.setState({brands: result})
        });
    };

    getModels = (idBrand) => {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
            id_brand: idBrand
        };
        instance.post('/getPriceCheckModels', postData).then(resp => {
            let result = resp.data.map( el => ({key: el.id_model_name, value: el.id_model_name, label: el.model_name}) )
            this.setState({modelli: result})
        });
    };

    getMemory = (idModel) => {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
            id_model_name: idModel
        };
        instance.post('/getPriceModelsMemory', postData).then(resp => {
            let result = resp.data.map( el => ({key: el.id_memory, value: el.id_memory, label: el.memory}) )
            this.setState({memoria: result})
        });
    };


    onBrandsChange = (event, values) => {
        if (values) this.setState({idBrandSelezionato: values.value, supervalutazioneScelta: null, gradoEsteticoScelto: null, priceData: null}, this.getModels( values.key)); else this.setState({modelli: []});
    };
    onModelsChange = (event, values) => {
        if (values) this.setState({idModelSelezionato: values.value, supervalutazioneScelta: null, gradoEsteticoScelto: null, priceData: null}, this.getMemory( values.value))
    };
    onMemoryChange = (event, values) => {
        if (values) this.setState({idMemoriaSelezionato: values.value, supervalutazioneScelta: null, gradoEsteticoScelto: null, priceData: null})
    };

    selezionaSupervalutazione = (data) => {
        console.log(data);
        let tot = this.state.prezzoCompleto - this.state.valoreSupervalutazione + data.supervalutazione;
        this.setState({prezzoCompleto: tot, supervalutazioneScelta: data.id_campagna, valoreSupervalutazione: data.supervalutazione, rowSelection: [], rowSelectionSupervalutazione: []});
    };

    filtraDevices = () => {
        this.setState({prezzoCompleto: 0, supervalutazioneScelta: null, valoreSupervalutazione: 0, rowSelectionSupervalutazione: null, gradoEsteticoScelto: null, rowSelection: null, valoreGradoEstetico: 0});

        var postData = {
            id_model_name: this.state.idModelSelezionato,
            id_brand: this.state.idBrandSelezionato,
            id_memory: this.state.idMemoriaSelezionato
        };
        instance.post('/getPriceCheckPrice', postData).then(resp => {
            console.log(resp.data);
            let prezzo = resp.data[0].working_price;
            let svalutazioni = [
                {'Grado': 'A',  'Descrizione': 'COME NUOVO','Svalutazione': '0%','valore_finale': (prezzo * 1).toFixed(2)  },
                {'Grado': 'A-', 'Descrizione': 'NO GRAFFI', 'Svalutazione': '5%', 'valore_finale': (prezzo * 0.95).toFixed(2)},
                {'Grado': 'B+', 'Descrizione': 'GRAFFI -1', 'Svalutazione': '10%', 'valore_finale': (prezzo * 0.9).toFixed(2)},
                {'Grado': 'B-', 'Descrizione': 'GRAFFI +1', 'Svalutazione': '20%', 'valore_finale': (prezzo * 0.8).toFixed(2)},
                {'Grado': 'C+', 'Descrizione': 'INCISIONI -1', 'Svalutazione': '50%', 'valore_finale': (prezzo * 0.5).toFixed(2)},
                {'Grado': 'C-', 'Descrizione': 'INCISIONI +1', 'Svalutazione': '80%', 'valore_finale': (prezzo * 0.2).toFixed(2)},
            ];
            this.setState({priceData: resp.data, priceSvalutazione: svalutazioni, prezzoCompleto: resp.data[0].working_price})
        });
        postData = {
            id_model_name: this.state.idModelSelezionato,
        };
        instance.post('/getCampagne', postData).then(resp => {
            console.log(resp.data);
            for (let i in resp.data) {
                resp.data[i].validita = new Date(resp.data[i].data_inizio).toLocaleDateString() + " - " + new Date(resp.data[i].data_fine).toLocaleDateString();
                let operations = [];
                let seleziona = (<AddCircleIcon color="#768828" style={{width: '27px', cursor: 'pointer', padding: '2px', color: '#235884', fontSize: 'xx-large',  marginRight: '5px'}} onClick={() => this.selezionaSupervalutazione(resp.data[i])}>add_circle</AddCircleIcon> )
                operations.push(seleziona);
                resp.data[i].azioni = operations;
            }
            this.setState({campagne: resp.data})
        });
    };

    azzeraFiltri = () => {
        this.setState({idBrandSelezionato: null, idModelSelezionato: null, idMemoriaSelezionato: null, modelli: []})
    };

    setRowSelection = (row) => {
        let tot = +row.original.valore_finale + (this.state.valoreSupervalutazione == null ? 0 : +this.state.valoreSupervalutazione);
        this.setState({prezzoCompleto: tot, gradoEsteticoScelto: row.id, rowSelection: row.id, valoreGradoEstetico: row.original.valore_finale });
    };

    setRowSelectionSupervalutazione = (row) => {
        let tot = this.state.prezzoCompleto - this.state.valoreSupervalutazione + row.original.supervalutazione;
        this.setState({prezzoCompleto: tot, supervalutazioneScelta: row.id, valoreSupervalutazione: row.original.supervalutazione, rowSelectionSupervalutazione: row.id});
    };



    render() {

        let authRedirect = null;
        console.log('[PriceCheck] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login"/>
        }

        let formMemoria = (
            <Autocomplete
                id="memoria"
                sx={{backgroundColor: '#f4f4f4', width: '300px', margin: 5, borderRadius: '4px', display: 'inline-block',
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: "#768828",
                        }
                }}
                // value={this.state.idMemoriaSelezionato}
                options={this.state.memoria}
                // getOptionLabel={option => option.label}
                autoHighlight
                size="small"
                variant="outlined"
                onChange={this.onMemoryChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{
                            style: { color: 'rgba(0, 0, 0, 0.54)' },
                        }}
                        label="Seleziona Capacità Dispositivo"
                        slotProps={{
                            htmlInput: {
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            },
                        }}
                    />
                )}
            />
        );

        let formBrand = (
            <Autocomplete
                id="brand"
                sx={{backgroundColor: '#f4f4f4', width: '300px', margin: 5, borderRadius: '4px',  display: 'inline-block',
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: "#768828",
                        }
                }}
                options={this.state.brands}
                autoHighlight
                size="small"
                variant="outlined"
                // value={this.state.idBrandSelezionato ? this.state.idBrandSelezionato.key : null}
                // isOptionEqualToValue={(option, value) => option.label === value}
                // getOptionLabel={(option) =>  option.label ? option.label : ""}
                onChange={this.onBrandsChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{
                            style: { color: 'rgba(0, 0, 0, 0.54)' },
                        }}
                        label="Seleziona Brand Dispositivo"
                        slotProps={{
                            htmlInput: {
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            },
                        }}
                    />
                )}
            />
        );

        let formModello = (
            <Autocomplete
                id="modello"
                sx={{backgroundColor: '#f4f4f4', width: '300px', margin: 5, borderRadius: '4px', display: 'inline-block',
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: "#768828",
                        }
                }}
                options={this.state.modelli}
                autoHighlight
                size="small"
                variant="outlined"
                // value={this.state.idModelSelezionato}
                onChange={this.onModelsChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{
                            style: { color: 'rgba(0, 0, 0, 0.54)' },
                        }}
                        label="Seleziona Modello Dispositivo"
                        slotProps={{
                            htmlInput: {
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            },
                        }}
                    />
                )}
            />
        );

        let boxFiltri = (
            <Box style={{textAlign: '-webkit-center'}}>
                <Box className={classes.FiltriBox}>
                    <h3 className={classes.FiltriTitle}>{('Cerca il prezzo')}</h3>
                    {formBrand}
                    {formModello}
                    {formMemoria}
                    <br />
                    <Button style={{margin: '10px', borderRadius: '15px'}} clicked={() => this.filtraDevices()}>{('CERCA')}</Button>
                    {/*<Button style={{backgroundColor: '#e5e5e5', color: '#235884', margin: '10px',borderRadius: '15px'}} clicked={() => this.azzeraFiltri()}>{('AZZERA FILTRI')}</Button>*/}
                </Box>
            </Box>
        );



        const newColumns = [
            {accessorKey: 'Grado', header: 'Grado', editable: false, size: 50,},
            {accessorKey: 'Descrizione', header: 'Descrizione', editable: false, size: 70,},
            {accessorKey: 'Svalutazione', header: 'Svalutazione', editable: false, size: 80},
            {accessorKey: 'valore_finale', header: 'Valore Finale €', editable: false, size: 100},
            // {accessorKey: 'azioni', header: 'Seleziona', editable: false, size: 20},
        ];

        const newColumnsCampagne = [
            {accessorKey: 'id_campagna', header: 'Campagna', editable: false, size: 100},
            {accessorKey: 'sku', header: 'SKU', editable: false, size: 100},
            {accessorKey: 'model_name_campagna', header: 'Device supervalutazione', editable: false, size: 100},
            {accessorKey: 'supervalutazione', header: 'Valore', editable: false, size: 80, Cell: ({ cell }) => cell.getValue() + " €"},
            {accessorKey: 'validita', header: 'Validità', editable: false, size: 100},
            // {accessorKey: 'azioni', header: 'Seleziona', editable: false, size: 50},
        ];

        let tabellaSvalutazioni = null;
        if (this.state.priceData != null && this.state.priceData.length > 0) {
            tabellaSvalutazioni = (
                <Box style={{textAlign: '-webkit-center', display: 'inline-block', width: '30%', verticalAlign: 'top'}}>
                    <Box className={classes.ResultBox}>
                        <h3 className={classes.FiltriTitle}>Svalutazioni per Grado </h3>
                        <MaterialReactTable
                            columns={newColumns}
                            data={this.state.priceSvalutazione}
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            enablePagination={false}
                            enableSorting={false}
                            enableBottomToolbar={false}
                            enableTopToolbar={false}
                            enableMultiRowSelection={false}
                            // enableRowSelection
                            getRowId={(row) => row.Grado}
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: () => this.setRowSelection(row),
                                sx: { cursor: 'pointer' },
                                selected: this.state.rowSelection === row.id
                            })}
                            state={ this.state.rowSelection }
                            onRowSelectionChange={(e) => this.setRowSelection(e)}
                            initialState={{ columnPinning: { right: ['azioni'] }, density: 'compact'}}

                        />
                    </Box>
                </Box>
            );
        }

        let campagne = null;
        if (this.state.campagne != null && this.state.campagne.length > 0) {
            campagne = (
                <Box style={{textAlign: '-webkit-center', display: 'inline-block', width: '70%', verticalAlign: 'top'}}>
                    <Box className={classes.ResultBox}>
                        <h3 className={classes.FiltriTitle}>Campagne attive per il dispositivo </h3>
                        <MaterialReactTable
                            columns={newColumnsCampagne}
                            data={this.state.campagne}
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            enablePagination={false}
                            enableSorting={false}
                            enableBottomToolbar={false}
                            enableTopToolbar={false}
                            getRowId={(row) => row.sku}
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: () => this.setRowSelectionSupervalutazione(row),
                                sx: { cursor: 'pointer' },
                                selected: this.state.rowSelectionSupervalutazione === row.id
                            })}
                            state={ this.state.rowSelectionSupervalutazione }
                            onRowSelectionChange={(e) => this.setRowSelectionSupervalutazione(e)}
                            initialState={{ columnPinning: { right: ['azioni'] }, density: 'compact'}}
                        />
                    </Box>
                </Box>
            );
        }

        let prezzoCompleto = null;


        let boxResult = null;
        if (this.state.priceData != null && this.state.priceData.length > 0) {
            boxResult = (
                <Box style={{textAlign: '-webkit-center'}}>
                    <Box className={classes.ResultBox}>
                        <h3 className={classes.FiltriTitle}>
                            Il valore massimo di acquisto per {this.state.priceData[0].brand_name} {this.state.priceData[0].model_name} {this.state.priceData[0].memory} è di <h1 style={{display: 'contents'}}>{this.state.priceData[0].working_price} Euro</h1>
                        </h3>
                        {prezzoCompleto}
                        {campagne}
                        {tabellaSvalutazioni}
                    </Box>
                </Box>
            )
        }

        if (this.state.prezzoCompleto > 0 && (this.state.supervalutazioneScelta != null || this.state.gradoEsteticoScelto != null)) {
            prezzoCompleto = (<h3 className={classes.FiltriTitle}>
                il valore massimo per {this.state.priceData[0].brand_name} {this.state.priceData[0].model_name} {this.state.priceData[0].memory} {this.state.supervalutazioneScelta != null ? "con la campagna di supervalutazione " + this.state.supervalutazioneScelta : ""} {this.state.gradoEsteticoScelto != null ? " grado estetico " + this.state.gradoEsteticoScelto : ""} è di <h1 style={{display: 'contents'}}>{this.state.prezzoCompleto.toFixed(2)} Euro</h1>
            </h3>);
            boxResult = (
                <Box style={{textAlign: '-webkit-center'}}>
                    <Box className={classes.ResultBox}>
                        {prezzoCompleto}
                        {campagne}
                        {tabellaSvalutazioni}
                    </Box>
                </Box>
            )
        }

        let stickyHeader = null;

        if (this.state.priceData != null && this.state.priceData.length > 0) {
            stickyHeader = (
                <div className={classes.Sticky} id="myHeader">
                    <h2>{"VALORE DEVICE " + this.state.priceData[0].working_price + " € "}</h2>
                </div>
            )
        }

        if (this.state.prezzoCompleto > 0 && (this.state.supervalutazioneScelta != null || this.state.gradoEsteticoScelto != null)) {
            if (this.state.supervalutazioneScelta != null) {
                stickyHeader = (
                    <div className={classes.Sticky} id="myHeader">
                        <h2>{"VALORE DEVICE " + (this.state.valoreGradoEstetico != null ? this.state.valoreGradoEstetico : this.state.priceData[0].working_price) + " € , CAMPAGNA: " + this.state.valoreSupervalutazione + " € - TOTALE: " + this.state.prezzoCompleto.toFixed(2) + " €"}</h2>
                    </div>
                )
            }
            else {
                stickyHeader = (
                    <div className={classes.Sticky} id="myHeader">
                        <h2>{"VALORE DEVICE " + (this.state.valoreGradoEstetico != null ? this.state.valoreGradoEstetico : this.state.priceData[0].working_price) + " €" + " - TOTALE: " + this.state.prezzoCompleto.toFixed(2) + " €"}</h2>
                    </div>
                )
            }
        }

        return (
            <Auxiliary>
                {stickyHeader}
                <div className={classes.PriceCheck}>
                    {authRedirect}
                    <h1 className={classes.Title}>PRICE CHECK</h1>
                    <div style={{height: 'auto', width: '100%', textAlign: '-webkit-center'}}>
                        {boxFiltri}
                        {boxResult}
                    </div>
                </div>
            </Auxiliary>
        );
    }

}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/price-check"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(PriceCheck)
